import type { Legend } from "@/stores/UserState";
import type { InjectionKey } from "vue";

export type ReadMode = "readonly" | "edit";

export type LegendConfiguratorModal = {
  show: (legend: Legend, mode: ReadMode) => void;
  hide: () => void;
};

export const LegendConfiguratorSymbol = Symbol(
  "Legend configurator provider identifier"
) as InjectionKey<LegendConfiguratorModal>;
