<script setup lang="ts">
import NoteConfigButton from "./NoteConfigButton.vue";
import NoteMenuState from "@/stores/NoteMenuState";
import type { NoteConfig } from "@/stores/UserState";
import { computed } from "vue";
import { useDeviceState } from "@/stores/DeviceState";
import { delay } from "lodash";

const deviceState = useDeviceState();

// categories is a Map of string, boolean,string represents the category and
// the boolean if it is actually checked or not
const categories = computed(() => {
  let categories: Map<string, boolean> = new Map<string, boolean>();
  for (const config of NoteMenuState.noteConfigs) {
    if (config.categories && config.categories.length) {
      for (const category of config.categories) {
        if (!categories.has(category)) {
          categories.set(category, true);
        }
      }
    }
  }
  return categories;
});

function persistActiveCategories() {
  delay(() => NoteMenuState.persistActiveCategories(), 200);
}

// filters the configs that have one or more categories in activeCategories
const filteredConfigs = computed(() => {
  const filteredConfigs: Map<string, NoteConfig> = new Map<
    string,
    NoteConfig
  >();
  for (const config of NoteMenuState.noteConfigs) {
    if (config.categories && config.categories.length) {
      for (const category of NoteMenuState.activeCategories) {
        if (config.categories.includes(category)) {
          // add defaults only if it is in the selected categories
          filteredConfigs.set(config.name, config);
        }
      }
    } else {
      // if no category set for default, show it anyway
      filteredConfigs.set(config.name, config);
    }
  }
  return filteredConfigs;
});
</script>

<template>
  <div class="custom-menu btn-group">
    <div v-if="categories.size" class="btn-group dropdown" role="group">
      <button
        ref="dropdownElem"
        type="button"
        class="btn btn-secondary dropdown-toggle capture"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-filter d-none d-sm-none d-md-inline-flex" />
      </button>
      <ul class="dropdown-menu dropdown-menu-dark p-2 capture">
        <li v-for="(category, index) in categories" :key="index">
          <input
            class="form-check-input me-1"
            type="checkbox"
            :id="category[0]"
            :value="category[0]"
            v-model="NoteMenuState.activeCategories"
            @click="persistActiveCategories()"
          />
          <label class="form-check-label" :for="category[0]">{{
            category[0]
          }}</label>
        </li>
      </ul>
    </div>
    <div v-if="deviceState.isSmall()" class="btn-group flex-wrap">
      <button
        class="btn btn-secondary dropdown-toggle capture"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{
          NoteMenuState.activeNote.label
            ? NoteMenuState.activeNote.label
            : NoteMenuState.activeNote.name
        }}
      </button>
      <ul
        class="dropdown-menu dropdown-menu-dark p-2"
        style="max-height: 60vh; overflow-y: auto"
      >
        <li v-for="config in filteredConfigs" :key="config[1].name">
          <NoteConfigButton
            :config="config[1]"
            :activeNoteConfig="NoteMenuState.activeNote"
          />
        </li>
      </ul>
    </div>
    <div v-else class="btn-group flex-wrap">
      <NoteConfigButton
        v-for="config in filteredConfigs"
        :key="config[1].name"
        :config="config[1]"
        :activeNoteConfig="NoteMenuState.activeNote"
      />
      <button v-if="!filteredConfigs.size" class="btn btn-secondary"></button>
    </div>
  </div>
</template>
<style>
.capture {
  pointer-events: all;
}
</style>
