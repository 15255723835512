<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import {
  SELECTION_GRID_EVENT_NAME,
  DrawSelectionGridEvent,
} from "@/open-cloud/draggers/Transform/transform.type";
import EditTextBtn from "./EditTextBtn.vue";
import { Facade } from "@/open-cloud/Facade";
import ScaleDropdown from "./ScaleDropdown.vue";
import type { ExposedScaleMenu } from "./floating.type";

const top = ref<number>(0); // distance to top in px
const left = ref<number>(0); // distance to left in px
const targets = ref<string[]>([]); // Handles of selected elements
const show = computed<boolean>(() => top.value != 0 && left.value != 0);
const scalesDropdownElement = ref<ExposedScaleMenu | null>(null);
const editTextBtnElement = ref<ExposedScaleMenu | null>(null);

const OFFSET_TOP = 35; // distance between bbox and menu in px

function handleGridEvent(event: DrawSelectionGridEvent) {
  targets.value = event.detail.targetHandles;
  if (targets.value.length) {
    const coords = Facade.getSelectedEntityExtentInCanvas(targets.value[0]);
    top.value = (coords[1][1] + OFFSET_TOP) / window.devicePixelRatio;
    left.value = (coords[1][0] + coords[2][0]) / (2 * window.devicePixelRatio);
  } else {
    top.value = 0;
    left.value = 0;
  }
  scalesDropdownElement?.value?.update(targets.value);
  editTextBtnElement?.value?.update(targets.value);
}

onMounted(() => {
  document.addEventListener(SELECTION_GRID_EVENT_NAME, handleGridEvent);
});

onUnmounted(() => {
  document.removeEventListener(SELECTION_GRID_EVENT_NAME, handleGridEvent);
});
</script>
<template>
  <div
    v-show="show"
    class="position-absolute translate-middle"
    :style="{ top: top + 'px', left: left + 'px' }"
  >
    <div class="btn-group">
      <ScaleDropdown :targets="targets" ref="scalesDropdownElement" />
      <EditTextBtn :target="targets[0]" ref="editTextBtnElement" />
    </div>
  </div>
</template>
