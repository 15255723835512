import type { Legend, NoteConfig } from "./stores/UserState";
export const DEFAULT_LEGEND: Legend = {
  id: 0,
  name: "DR",
  version: 4,
  note_configs: [
    {
      name: "Fissure",
      categories: ["Béton"],
      type: "default",
      label: "Fi",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRFissures",
      },
    },
    {
      name: "Fracture",
      categories: ["Béton"],
      type: "default",
      label: "Fr",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRFissures",
      },
    },
    {
      name: "micro-fissure",
      categories: ["Béton"],
      type: "default",
      label: "µFi",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRFissures",
      },
    },
    {
      name: "Faïençage",
      categories: ["Béton"],
      type: "default",
      label: "Fa",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRFissures",
      },
    },
    {
      name: "Végétation",
      categories: ["Végétation"],
      type: "default",
      label: "Vég",
      hatch: { name: "GRASS" },
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRVégétation",
      },
    },
    {
      name: "Arbre",
      categories: ["Végétation"],
      type: "default",
      label: "Arbre",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRVégétation",
      },
    },
    {
      name: "Mousse",
      categories: ["Végétation"],
      type: "default",
      label: "Mo",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRVégétation",
      },
    },
    {
      name: "Traces d'humidité",
      categories: ["Humidité"],
      type: "default",
      label: "Hum",
      hatch: { name: "HUM" },
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRHumidité",
      },
    },
    {
      name: "Stalactite",
      categories: ["Humidité"],
      type: "default",
      label: "Sta",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRHumidité",
      },
    },
    {
      name: "Calcite",
      categories: ["Humidité"],
      type: "default",
      label: "Cal",
      hatch: { name: "HUM" },
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRHumidité",
      },
    },
    {
      name: "Eclat",
      categories: ["Béton", "Maçonnerie"],
      type: "default",
      label: "Ec",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRBéton",
      },
    },
    {
      name: "Corrosion",
      categories: ["Béton", "Métal"],
      type: "default",
      label: "Co",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Eclat en formation",
      categories: ["Béton"],
      label: "EcF",
      props: {
        model: "ACTIVE",
        color: "kBylayer",
        layername: "DRBéton",
      },
    },
    {
      name: "Epaufrure",
      categories: ["Béton", "Maçonnerie"],
      type: "default",
      label: "Epf",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRBéton",
      },
    },
    {
      name: "Aciers apparents oxydés",
      categories: ["Béton"],
      type: "default",
      label: "AAO",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRBéton",
      },
    },
    {
      name: "Nid de Cailloux",
      categories: ["Béton"],
      type: "default",
      label: "NdC",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRBéton",
      },
    },
    {
      name: "Ségrégation",
      categories: ["Béton"],
      type: "default",
      label: "Ség",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRBéton",
      },
    },
    {
      name: "Bombement",
      label: "Bomb",
      type: "default",
      categories: ["Général"],
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Déversement",
      label: "Dév",
      type: "default",
      categories: ["Général"],
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Eraflures",
      label: "Era",
      type: "default",
      categories: ["Général"],
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Divers",
      label: "Divers",
      type: "default",
      categories: ["Général"],
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Corrosion",
      categories: ["Métal"],
      type: "default",
      label: "Cor",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Déchaussement",
      categories: ["Maçonnerie"],
      type: "default",
      label: "Dech",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Lacune",
      categories: ["Maçonnerie"],
      type: "default",
      label: "Lac",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Erosion",
      categories: ["Maçonnerie", "Abords"],
      type: "default",
      label: "Ero",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Disjointement",
      categories: ["Maçonnerie"],
      type: "default",
      label: "Disj",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Tassement",
      categories: ["Chaussée"],
      type: "default",
      label: "Tas",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Nid de Poule",
      categories: ["Chaussée"],
      type: "default",
      label: "NdP",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Ravinement",
      categories: ["Abords"],
      type: "default",
      label: "Rav",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Affouillement",
      categories: ["Abords"],
      type: "default",
      label: "Aff",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRDésordres",
      },
    },
    {
      name: "Photos",
      categories: ["Photos"],
      type: "photo",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRPhotos",
      },
    },
    {
      name: "Notes",
      categories: ["Général"],
      type: "general",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRNotes",
      },
    },
    {
      name: "Dépots divers",
      categories: ["Général"],
      label: "Depots",
      type: "general",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRNotes",
      },
    },
    {
      name: "Ragréage",
      categories: ["Réparations"],
      label: "Rg",
      type: "general",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRRéparations",
      },
    },
    {
      name: "Reprise de bétonnage",
      categories: ["Réparations"],
      label: "RB",
      type: "general",
      props: {
        model: "ACTIVE",
        color: "kByLayer",
        layername: "DRRéparations",
      },
    },
  ],
  layer_configs: [
    {
      name: "DRFissures",
      visibility: true,
      color: "255,0,0",
    },
    {
      name: "DRDésordres",
      visibility: true,
      color: "255,140,0",
    },
    {
      name: "DRBéton",
      visibility: true,
      color: "125,255,0",
    },
    {
      name: "DRHumidité",
      visibility: true,
      color: "0,0,255",
    },
    {
      name: "DRVégétation",
      visibility: true,
      color: "0,255,0",
    },
    {
      name: "DRPhotos",
      visibility: true,
      color: "255,191,0",
    },
    {
      name: "DRNotes",
      visibility: true,
      color: "125,125,125",
    },
    {
      name: "DRRéparations",
      visibility: true,
      color: "0,255,127",
    },
  ],
  scale_configs: [
    {
      name: "1-50",
      props: {
        textstyle: {
          name: "DR 1-50",
          filename: "Arial",
          typeface: "Arial",
          size: 0.1,
        },
      },
    },
    {
      name: "1-75",
      props: {
        textstyle: {
          name: "DR 1-75",
          filename: "Arial",
          typeface: "Arial",
          size: 0.15,
        },
      },
    },
    {
      name: "1-100",
      props: {
        textstyle: {
          name: "DR 1-100",
          filename: "Arial",
          typeface: "Arial",
          size: 0.2,
        },
      },
    },
    {
      name: "1-125",
      props: {
        textstyle: {
          name: "DR 1-125",
          filename: "Arial",
          typeface: "Arial",
          size: 0.25,
        },
      },
    },
    {
      name: "1-150",
      props: {
        textstyle: {
          name: "DR 1-150",
          filename: "Arial",
          typeface: "Arial",
          size: 0.3,
        },
      },
    },
    {
      name: "1-200",
      props: {
        textstyle: {
          name: "DR 1-200",
          filename: "Arial",
          typeface: "Arial",
          size: 0.4,
        },
      },
    },
    {
      name: "1-250",
      props: {
        textstyle: {
          name: "DR 1-250",
          filename: "Arial",
          typeface: "Arial",
          size: 0.5,
        },
      },
    },
    {
      name: "1-300",
      props: {
        textstyle: {
          name: "DR 1-300",
          filename: "Arial",
          typeface: "Arial",
          size: 0.8,
        },
      },
    },
    {
      name: "1-600",
      props: {
        textstyle: {
          name: "DR 1-600",
          filename: "Arial",
          typeface: "Arial",
          size: 1.2,
        },
      },
    },
  ],
};

export const DEFAULT_PHOTO_NOTE_CONFIG: NoteConfig = {
  name: "Photos",
  categories: ["Photos"],
  type: "photo",
  props: {
    model: "ACTIVE",
    color: "kByLayer",
    layername: "DRPhotos",
  },
};

export const DEFAULT_NOTE_CONFIG: NoteConfig = {
  name: "DEFAULT",
  props: {
    model: "ACTIVE",
    color: "255,0,0",
    textstyle: {
      name: "default",
      size: 0.2,
    },
  },
};
