<script setup lang="ts">
import TextInput from "@/components/canvas/Tools/TextInput/TextInput.vue";
import { provide, ref, type Ref } from "vue";
import NoteMenuState from "@/stores/NoteMenuState";
import Modal from "bootstrap/js/dist/modal";
import { ModalKeyboardSymbol } from "./keyboardModalSymbols";
import type {
  ExposedHtmlInput,
  TextTarget,
} from "@/components/canvas/Tools/TextInput/text.type";

const NUMBERS = [
  "0.1",
  "0.2",
  "0.3",
  "0.4",
  "0.5",
  "0.6",
  "0.7",
  "0.8",
  "0.9",
  "1",
  "1.1",
  "1.2",
];

const target: Ref<TextTarget> = ref({
  message: "",
  update: () => {},
});

const TextInputHTML = ref<ExposedHtmlInput | null>(null);
const modalKeyboard = ref();

function deleteChars(numChar = 1) {
  const newValue = target.value.message.slice(0, -1 * numChar);
  target.value.update(newValue);
}
function blur() {
  TextInputHTML.value?.blur();
}

function addToInput(text: string) {
  target.value.update(target.value.message + text);
}

function show(newTarget: TextTarget) {
  Modal.getOrCreateInstance(modalKeyboard.value).show();
  target.value = newTarget;
}

function hide() {
  Modal.getOrCreateInstance(modalKeyboard.value).hide();
}

provide(ModalKeyboardSymbol, {
  show,
  hide,
});
</script>
<template>
  <div
    ref="modalKeyboard"
    class="modal"
    tabindex="-1"
    id="dr-modal-keyboard"
    aria-labelledby="modalTextPanel"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div class="grid">
            <div class="g-col-4 m-2 sticky-top">
              <form class="ms-1" @submit.stop.prevent="blur()">
                <div class="input-group">
                  <TextInput
                    :target="target"
                    ref="TextInputHTML"
                    :prevent-focus-on-clear="true"
                  />
                </div>
              </form>
            </div>
            <div class="g-col-4 m-2">
              <button class="btn btn-secondary me-1" @click="addToInput(' ')">
                esp
              </button>
              <button class="btn btn-secondary me-1" @click="addToInput('+')">
                <i class="bi bi-plus" />
              </button>
              <button class="btn btn-secondary me-1" @click="addToInput('/')">
                /
              </button>
              <button class="btn btn-secondary" @click="deleteChars()">
                <i class="bi bi-backspace" />
              </button>
            </div>
            <div class="g-col-4 m-2">
              <ul class="list-group list-group-horizontal flex-wrap">
                <li
                  v-for="config in NoteMenuState.noteConfigs"
                  :key="config.name"
                  class="list-group-item d-inline-flex justify-content-between align-items-center"
                  @click="addToInput(config.label || config.name)"
                >
                  {{ config.label || config.name }}
                </li>
              </ul>
            </div>
            <div class="g-col-4 m-2">
              <ul class="list-group list-group-horizontal flex-wrap">
                <li
                  v-for="anno in NUMBERS"
                  :key="anno"
                  class="list-group-item d-inline-flex justify-content-between align-items-center"
                  @click="addToInput(anno)"
                >
                  {{ anno }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <slot></slot>
</template>
./keyboardModalSymbols
