<script setup lang="ts">
import type { Legend } from "@/stores/UserState";
import UserState from "@/stores/UserState";
import { useLegendConfigurator } from "@/components/composable/useLegendConfigurator";
const configurator = useLegendConfigurator();
function open(legend: Legend) {
  configurator?.show(legend, "readonly");
}
</script>
<template>
  <ul class="list-group" ref="list">
    <li
      v-for="legend in UserState.data?.legends"
      :key="legend.name"
      class="list-group-item list-group-item-action"
      @click="open(legend)"
    >
      {{ legend.name }}
    </li>
  </ul>
</template>
