import Toolbox from "@/open-cloud/builders/ODAToolbox";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";

/**
 * Problem: `Entity.rotate` sets the rotation, starting from 0.
 * Meaning if we call it twice with rotate(x) and then rotate(y), the entity will be rotated y and not x+y.
 * To solve this, we use matrixes which allow more flexibility. From then we set on using matrices for all cases to keep it consistent.
 */
export function computeRotation(
  eventWorldPoint: VisualizeJS.Point3,
  centerPoint: VisualizeJS.Point3,
  rotationPoint: VisualizeJS.Point3
): VisualizeJS.Matrix3d {
  // calc the angle the user is creating while dragging
  let angle =
    Math.atan2(
      rotationPoint[1] - centerPoint[1],
      rotationPoint[0] - centerPoint[0]
    ) -
    Math.atan2(
      eventWorldPoint[1] - centerPoint[1],
      eventWorldPoint[0] - centerPoint[0]
    );
  angle = angle > 0 ? angle : angle + 2 * Math.PI;
  angle = -angle;

  return OdaGeometryUtils.getRotationMatrix(centerPoint, angle);
}

export function computeTranslation(
  eventWorldPoint: VisualizeJS.Point3,
  initialPosition: VisualizeJS.Point3
): VisualizeJS.Matrix3d {
  const vector: VisualizeJS.Vector3 = [
    eventWorldPoint[0] - initialPosition[0],
    eventWorldPoint[1] - initialPosition[1],
    0,
  ];
  return OdaGeometryUtils.getTranslationMatrix(vector);
}

/**
 *
 * @param eventWorldPoint position of the dragged handle
 * @param initialPosition initial position of the dragged handle
 * @param origin the handle that stays "anchored" at its location during the scaling operation
 * @param axles to scale along only one axis
 */
export function computeFreeResize(
  eventWorldPoint: VisualizeJS.Point3,
  initialPoint: VisualizeJS.Point3,
  origin: VisualizeJS.Point3,
  axles: { x?: boolean; y?: boolean } = { x: true, y: true }
): VisualizeJS.Matrix3d {
  const originalDistanceX = initialPoint[0] - origin[0];
  const originalDistanceY = initialPoint[1] - origin[1];

  const xDraggingDistance = eventWorldPoint[0] - origin[0];
  const xRatio = xDraggingDistance / originalDistanceX;

  const yDraggingDistance = eventWorldPoint[1] - origin[1];
  const yRatio = yDraggingDistance / originalDistanceY;

  const ratioVector: VisualizeJS.Vector3 = [1, 1, 1];
  if (axles.x) ratioVector[0] = xRatio;
  if (axles.y) ratioVector[1] = yRatio;

  // Scaling makes the entity move, as a result of all its coordinate being scaled.
  // So we need to compensate with a translation to make it scale while being fixed on its center
  const shiftVector: VisualizeJS.Vector3 = [0, 0, 0];
  if (axles.x) shiftVector[0] = origin[0] * (1 - xRatio);
  if (axles.y) shiftVector[1] = origin[1] * (1 - yRatio);

  return OdaGeometryUtils.getScaleMatrix(ratioVector).preMultBy(
    OdaGeometryUtils.getTranslationMatrix(shiftVector)
  );
}

/**
 *
 * @param eventWorldPoint position of the dragged handle
 * @param initialPosition initial position of the dragged handle
 * @param origin the handle that stays "anchored" at its location during the scaling operation
 */
export function computeConstraintResize(
  eventWorldPoint: VisualizeJS.Point3,
  initialPosition: VisualizeJS.Point3,
  origin: VisualizeJS.Point3
) {
  const originalDistanceToCenter = Toolbox.computeDistance2D(
    origin,
    initialPosition
  );

  const draggingDistanceToCenter = Toolbox.computeDistance2D(
    origin,
    eventWorldPoint
  );

  const ratio = draggingDistanceToCenter / originalDistanceToCenter;

  const shiftVector: VisualizeJS.Vector3 = [
    origin[0] * (1 - ratio),
    origin[1] * (1 - ratio),
    0,
  ];

  // Scaling makes the entity move, as a result of all its coordinate being scaled.
  // So we need to compensate with a translation to make it scale while being fixed on its center
  return OdaGeometryUtils.getScaleMatrix([ratio, ratio, 1]).preMultBy(
    OdaGeometryUtils.getTranslationMatrix(shiftVector)
  );
}
