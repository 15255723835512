import SequentialEntityDragger from "./SequentialEntityDragger";
import { clearCursor, isExtremitiesClose } from "../composables/close.utils";
import {
  appendCursorEntityToModel,
  ORIGIN_DESIGN_PROPS,
  ORIGIN_RADIUS,
} from "../composables/cursors";
import { checkIfCloseAndAddCursorStart } from "../composables/close.utils";
import ShellBuilder from "@/open-cloud/builders/ShellBuilder";
import HatchLibrary from "@/hatches/library";
import type { HatchProp } from "@/stores/UserState";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";

export default class PolylineDragger extends SequentialEntityDragger {
  coordinates: number[] = [];
  cursorId: VisualizeJS.OdTvEntityId | null = null;

  end(x: number, y: number) {
    if (!this.shadowId) return;
    // if extremities are close, swap end corner with start
    let isClose = false;
    if (this.viewer.closeContour) {
      isClose = isExtremitiesClose(
        [...this.coordinates, ...this.endCornerWCS],
        this.viewer
      );
      if (isClose) {
        this.endCornerWCS = this.coordinates.slice(undefined, 3) as [
          number,
          number,
          number
        ];
      }
    }

    super.end(x, y, false);
    clearCursor(this.cursorId, this.viewer);
    if (isClose) {
      if (
        this.viewer.activeNoteConfig.hatch != undefined &&
        this.entity &&
        !this.entity.isNull()
      ) {
        this.drawHatches(
          this.entity,
          this.coordinates,
          this.viewer.activeNoteConfig.hatch
        );
      }
      this._setNoteConfigProperties(this.entity);
      this.clear();
    } else {
      this._setNoteConfigProperties(this.entity);
    }
  }

  drawShadow() {
    if (this.endCornerWCS.length) {
      const model = this.viewer.modelBuilder.findModel("MUP");
      const radius = this.viewer.toolbox.screenDistanceToWorld(ORIGIN_RADIUS);
      this.shadowId = appendCursorEntityToModel(
        model,
        this.endCornerWCS,
        radius
      );
      this._setProperties(this.shadowId, ORIGIN_DESIGN_PROPS);
      if (!this.viewer.closeContour) return;
      this.cursorId = checkIfCloseAndAddCursorStart(
        this.cursorId,
        [...this.coordinates, ...this.endCornerWCS],
        this.viewer,
        12
      ); // 4 points mini ie a triangle
      model.delete();
    }
  }

  createNew(): VisualizeJS.OdTvEntityId {
    const model: VisualizeJS.TvModel =
      this.viewer.modelBuilder.findModel("ACTIVE");
    const newEntId = model.appendEntity("DR_POLYLINE");
    const newEntPtr = newEntId.openObject();
    this.coordinates.push(...this.endCornerWCS);
    const poly = newEntPtr.appendPolyline(this.coordinates);
    poly.delete();
    newEntPtr.delete();
    return newEntId;
  }

  drawHatches(
    entId: VisualizeJS.OdTvEntityId,
    points: number[],
    hatch: HatchProp
  ) {
    // get user Turn Direction to calculate face normal
    // if turn direction is clockwise, faces normal will point backward
    // hatch will then be symetric relative to Y-axis
    const turnDirection = OdaGeometryUtils.getTurnDirection(this.coordinates);

    const flipY = turnDirection === "Clockwise" ? true : false;
    const item = HatchLibrary.getByName(hatch.name, hatch.rotation, flipY);

    if (!item) return;

    const geomId = ShellBuilder.appendPolylineShell(entId, points);

    this.viewer.shellBuilder.setHatchPattern(geomId, item.hatches);

    geomId.delete();
  }

  clear() {
    super.clear();
    this.coordinates = [];
  }
}

/* methods that where initially coded to work with existing polyline,
 it is more convenient to store coordinates in a prop

  getPolylinePointsAs1DArray(entityId: VisualizeJS.OdTvEntityId): number[] {
    const points = this.getPolylinePoints(entityId);
    let points1D: number[] = [];
    for (let i = 0; i < points.length; i++) {
      points1D = points1D.concat(points[i]);
    }
    return points1D;
  }

  getPolylinePoints(entityId: VisualizeJS.OdTvEntityId): OdTvPoint[] {
    const polyline = this.getPolylineData(entityId);
    if (polyline != null) {
      return polyline.getPoints();
    } else {
      return [];
    }
  }

  getPolylineData(entityId: VisualizeJS.OdTvEntityId): OdTvPolylineData | null {
    const entity = entityId.openObject();
    const iterator = entity.getGeometryDataIterator();
    for (; !iterator.done(); iterator.step()) {
      const geomId = iterator.getGeometryData();
      if (
        geomId.getType() ===
        this.viewer.visLib().OdTvGeometryDataType.kPolyline.value
      ) {
        return geomId.openAsPolyline();
      }
    }
    Logger.debug("No polyline geometry found in entityId");
    return null;
  }

  getPoint(entityId: VisualizeJS.OdTvEntityId, index: number): OdTvPoint | null {
    const polyline = this.getPolylineData(entityId);
    if (polyline) {
      const count = polyline.getPointsCount();
      if (count > index) {
        // count >= index + 1
        const coordinates = polyline.getPoints();
        return coordinates.slice(index * 3, index * 3 + 2) as OdTvPoint;
      }
    }
    return null;
  }

  getLastPoint(entityId: VisualizeJS.OdTvEntityId) {
    const polyline = this.getPolylineData(entityId);
    if (polyline) {
      const coordinates = polyline.getPoints();
      if (coordinates.length >= 3) {
        return coordinates.slice(-3);
      }
    }
  }
  */
