import type { EntityProps } from "../builders/EntityBuilder";
import { DraggerEvents } from "@/open-cloud/draggers/draggers.type";
import DRBaseDragger from "./DRBaseDragger";
import Toolbox from "../builders/ODAToolbox";
import { Logger } from "@/logger";
import type { pointArray } from "../types/oda.types";
// Defined in OdBaseDragger
// This is the name of the overlayed view containing Markup Model
// This view is a Sibling of the main view, meaning it has same properties
// const OVERLAY_VIEW_NAME = "$OVERLAY_VIEW_NAME";

// Defines the DesignProps of the selection window
export const SELECTION_WINDOW_ENTITY_PROPS: EntityProps = {
  color: "125,125,125",
  transparency: {
    edge: 0.5,
    face: 0.8,
  },
};

export const MINIMUM_WINDOW_SIZE = 10; // px

// Set size of the pixelBox, touch tends to be less precise than click
// even with a stylus. Ideally, we would like to set it depending on the
// type of pointerType (in a PointerEvent). But pointerEvents are not yet
// available. The app is done for mobile first so we adjust it for a better
// mobile UX
const PIX_BOX_SIZE = 8; //px // seems to be be fairly working on touch devices

type SELECT_MODE = "point" | "window" | "crossing"; // Other modes are available in ODA

export default class SelectDragger extends DRBaseDragger {
  // To solve the issue of a very light drag not counting as a click.
  // That was a problem to unselect, when you really had to click precisely without motion,
  // or else it would try to select something in the few pixel area dragged (instead of unselecting).
  // We're counting the number of drag events and considering we need FIRST_DRAG_EVENT_THRESHOLD to be sure it is not a click
  dragCount = 0;
  static FIRST_DRAG_EVENT_THRESHOLD = 2;
  // FIXME : we use this because touchend is triggered onhover and unselect items
  hasStarted = false;
  hasDragged = false;
  tempSelectionSet: VisualizeJS.OdTvSelectionSet | null = null;

  start(x: number, y: number) {
    super.start(x, y);
    this.hasStarted = true;
    this.dragCount = 0;
  }

  drag(x: number, y: number) {
    super.drag(x, y);
    // on touch device, drag can be triggered very fast. Then, select point which triggers unselect never happens
    // we put a minimum window size in order to improve ux
    if (
      this.startCornerCanvas.length === 3 &&
      this.endCornerCanvas.length === 3
    ) {
      const window_size = Toolbox.computeDistance2D(
        [x, y, 0],
        this.startCornerCanvas
      );
      if (this.isDragging && window_size >= MINIMUM_WINDOW_SIZE) {
        this.dragCount++;
        this.hasDragged = true;
        this._updateFrame();
        if (this.startCornerCanvas[0] - this.endCornerCanvas[0] < 0) {
          this.select("window");
        } else if (this.startCornerCanvas[0] - this.endCornerCanvas[0] > 0) {
          this.select("crossing");
        }
      }
    }
  }

  end(x: number, y: number) {
    if (this.hasStarted) {
      // cf the dragCount declaration for explanations
      const processHasDrag =
        this.hasDragged &&
        this.dragCount >= SelectDragger.FIRST_DRAG_EVENT_THRESHOLD;
      // if the user has not dragged, then select a point and log prop in debug mode
      if (!processHasDrag) {
        this.select("point");
        this.viewer.selectionSetBuilder.logSelectionSetProperties(
          this.tempSelectionSet
        );
      }
      // Put temporary selected items in the viewer selection set
      if (this.tempSelectionSet && this.tempSelectionSet.numItems() > 0) {
        if (!this.viewer.selectionSet) {
          const lib = this.subject.visLib();
          this.viewer.selectionSet =
            new lib.OdTvSelectionSet() as VisualizeJS.OdTvSelectionSet;
        }
        this.viewer.selectionSet.add(this.tempSelectionSet);
      }
      Logger.info(
        `SelectDragger.end : ${
          this.viewer.activeDragger()?.name
        } selected ${this.viewer.selectionSet?.numItems()} items`
      );
      this.clear();
      this.viewer.emitEvent({ type: DraggerEvents.SelectionEnded });
    }
  }

  clear() {
    super.clear();
    this.clearShadow();
    this.tempSelectionSet?.delete();
    this.tempSelectionSet = null;
    this.hasDragged = false;
    this.hasStarted = false;
  }

  onescape(): void {
    this.clearShadow();
    this.unselect();
  }

  _updateFrame() {
    if (
      this.endCornerCanvas.length === 3 &&
      this.startCornerCanvas.length === 3
    ) {
      this.refreshShadowEntity();
      if (
        !this.shadowId ||
        this.startCornerWCS.length === 0 ||
        this.endCornerWCS.length === 0
      )
        return;
      SelectDragger.drawSelectionWindow(
        this.shadowId,
        this.startCornerWCS,
        this.endCornerWCS
      );
      this._setProperties(this.shadowId, SELECTION_WINDOW_ENTITY_PROPS);
    }
  }

  static drawSelectionWindow(
    entId: VisualizeJS.OdTvEntityId,
    startWCS: pointArray,
    endWCS: pointArray
  ) {
    const entPtr = entId.openObject();
    const point2: pointArray = [endWCS[0], startWCS[1], startWCS[2]];
    const point4: pointArray = [startWCS[0], endWCS[1], startWCS[2]];
    // On some drawings z != 0 and it triggers errors, so we put everithing on the same plane
    endWCS[2] = startWCS[2];
    const data = [...startWCS, ...point2, ...endWCS, ...point4];
    const geomId = entPtr.appendPolygon(data);
    const gon = geomId.openAsPolygon();
    gon.setFilled(true);

    Toolbox.deleteAll([gon, geomId, entPtr]);
  }

  select(mode: SELECT_MODE) {
    const viewer = this.subject.visViewer();
    const aView = viewer.activeView;
    const aModel = viewer.getActiveModel();
    const lib = this.subject.visLib();

    const sOpt =
      new lib.OdTvSelectionOptions() as VisualizeJS.OdTvSelectionOptions;
    sOpt.setPickBoxSize(PIX_BOX_SIZE);

    let dcPoints: number[] = [];

    if (mode === "point") {
      sOpt.setMode(lib.SelectionMode.kPoint);
      dcPoints = this.startCornerCanvas.slice(0, 2);
    } else if (mode === "window") {
      sOpt.setMode(lib.SelectionMode.kWindow);
      dcPoints = [
        ...this.startCornerCanvas.slice(0, 2),
        ...this.endCornerCanvas.slice(0, 2),
      ];
    } else if (mode === "crossing") {
      sOpt.setMode(lib.SelectionMode.kCrossing);
      dcPoints = [
        ...this.startCornerCanvas.slice(0, 2),
        ...this.endCornerCanvas.slice(0, 2),
      ];
    }

    // If the mode is window or crossing and there was something hightlighted,
    // unhighlight it
    if (mode === "crossing" || mode === "window") {
      this.viewer.selectionSetBuilder.highlightSelectionSet(
        this.tempSelectionSet,
        false
      );
    }

    this.tempSelectionSet = aView.select(dcPoints, sOpt, aModel);
    if (this.tempSelectionSet?.numItems()) {
      aView.highlightAll(this.tempSelectionSet, true);
    } else if (mode === "point") {
      // Tap a blank zone of the canvas triggers unselect
      this.unselect();
    }
  }

  unselect() {
    this.viewer.selectionSetBuilder.highlightSelectionSet(
      this.viewer.selectionSet,
      false
    );
    this.viewer.selectionSet?.delete();
    this.viewer.selectionSet = null;
    this.onmessage({ type: DraggerEvents.UnselectAll });
    this.viewer.update();
  }
}
