import { TextBuilder, type TextData } from "@/open-cloud/builders/TextBuilder";
import OneWayEntityDragger from "./OneWayEntityDragger";
import {
  GeometryBuilder,
  type EllipseData,
} from "@/open-cloud/builders/GeometryBuilder";
import ShellBuilder from "@/open-cloud/builders/ShellBuilder";
import HatchLibrary from "@/hatches/library";
import type { HatchProp } from "@/stores/UserState";

export default class EllipseDragger extends OneWayEntityDragger {
  _updateFrame(): void {
    if (this.startCornerWCS.length === 3 && this.endCornerWCS.length === 3) {
      this.refreshShadowEntity();
      if (this.shadowId) {
        this.drawEllipse(
          this.shadowId,
          this.startCornerWCS,
          this.endCornerWCS,
          this.viewer.activeNoteConfig.label
        );
        if (this.viewer.activeNoteConfig.hatch != undefined) {
          this.drawHatches(
            this.shadowId,
            this.startCornerWCS,
            this.endCornerWCS,
            this.viewer.activeNoteConfig.hatch
          );
        }
        this.drawHVMeasurements();
        this._setNoteConfigProperties(this.shadowId);
      }
    }
  }

  drawEllipse(
    entityId: VisualizeJS.OdTvEntityId,
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number],
    label?: string
  ) {
    const corner1: [number, number, number] = [
      startCornerWCS[0],
      endCornerWCS[1],
      0,
    ];
    const corner2: [number, number, number] = [
      endCornerWCS[0],
      startCornerWCS[1],
      0,
    ];
    const center = this.viewer.toolbox.computeCenter(
      startCornerWCS,
      endCornerWCS
    );
    const major = this.viewer.toolbox.computeCenter(endCornerWCS, corner1);

    const minor = this.viewer.toolbox.computeCenter(endCornerWCS, corner2);
    const geomId = GeometryBuilder.addEllipse(entityId, {
      center: center,
      majorPoint: major,
      minorPoint: minor,
    });
    if (label && this.viewer.label) {
      const data: TextData = {
        refpoint: center,
        message: label,
        alignmentmode: 10,
      };
      TextBuilder.addText(entityId, data);
    }
    geomId.delete();
  }

  drawHatches(
    entId: VisualizeJS.OdTvEntityId,
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number],
    hatch: HatchProp
  ) {
    const item = HatchLibrary.getByName(hatch.name, hatch.rotation);
    if (!item) return;

    const data: EllipseData = {
      center: this.getCenter(startCornerWCS, endCornerWCS),
      majorPoint: this.getMajorPoint(startCornerWCS, endCornerWCS),
      minorPoint: this.getMinorPoint(startCornerWCS, endCornerWCS),
    };
    const geomId = ShellBuilder.appendEllipseShell(entId, data);

    this.viewer.shellBuilder.setHatchPattern(geomId, item.hatches);
    geomId.delete();
  }

  getCenter(
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number]
  ): [number, number, number] {
    return [
      (startCornerWCS[0] + endCornerWCS[0]) / 2,
      (startCornerWCS[1] + endCornerWCS[1]) / 2,
      (startCornerWCS[2] + endCornerWCS[2]) / 2,
    ];
  }

  getMajorPoint(
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number]
  ): [number, number, number] {
    return [
      endCornerWCS[0],
      (startCornerWCS[1] + endCornerWCS[1]) / 2,
      endCornerWCS[2],
    ];
  }

  getMinorPoint(
    startCornerWCS: [number, number, number],
    endCornerWCS: [number, number, number]
  ): [number, number, number] {
    return [
      (startCornerWCS[0] + endCornerWCS[0]) / 2,
      endCornerWCS[1],
      endCornerWCS[2],
    ];
  }
}
