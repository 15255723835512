<script setup lang="ts">
import type { NoteConfig } from "@/stores/UserState";
import { computed } from "vue";
import { useDrag } from "vue3-dnd";
import { DnDTypes } from "@/utils/DnD.types";
import { Logger } from "@/logger";
import NoteMenuState from "@/stores/NoteMenuState";
import CanvasSettingsState from "@/stores/CanvasSettingsState";

const props = defineProps<{
  config: NoteConfig;
  activeNoteConfig: NoteConfig | null;
}>();

const displayedLabel = computed<string>(() => {
  return props.config.label || props.config.name;
});

const isActive = computed<boolean>(() => {
  return Object.is(props.config, props.activeNoteConfig);
});

const [, drag] = useDrag(() => ({
  type: DnDTypes.NOTE,
  item: () => ({
    note: props.config,
  }),
}));

function activateNoteConfig(config: NoteConfig) {
  Logger.info(`NoteConfigMenu.vue : activate noteconfig ${config.name}`);
  NoteMenuState.setActiveNoteConfig(config);
  CanvasSettingsState.updateAndPersist();
}
</script>

<template>
  <button
    :ref="drag"
    class="btn design-button flex-grow-0"
    @pointerup="activateNoteConfig(config)"
    :class="{
      'btn-dark': isActive,
      'btn-secondary': !isActive,
    }"
  >
    {{ displayedLabel }}
  </button>
</template>

<style>
.design-button {
  white-space: nowrap;
  pointer-events: all;
  touch-action: none;
}
</style>
@/stores/UserState
