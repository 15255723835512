import { ref } from "vue";
import { defineStore } from "pinia";
import { Monitor } from "@/utils/monitor";
import { Logger } from "@/logger";
import api from "@/APIClient";

export const MOUSE_EVENTS = ["mousedown", "mousemove", "mouseup", "mouseleave"];

export const useDeviceState = defineStore("deviceState", () => {
  const isOnline = ref(true);
  const onlineStateMonitor = new Monitor(checkIfOnline, { intervalInMs: 3000 });

  const ocsURL = import.meta.env.DR_ODA_API_URL;
  // Refer to this article : https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
  function isTouchDevice(): boolean {
    return (
      "ontouchstart" in window || // Most modern touch devices
      (window.DocumentTouch && document instanceof DocumentTouch) || // Fallback for older devices
      navigator.maxTouchPoints > 0 || // For some newer devices with maxTouchPoints property
      (window.matchMedia && window.matchMedia("(pointer: coarse)").matches) // Detects coarse pointers, typical of touch screens
    );
  }

  async function checkIfOnline() {
    Logger.info(`DeviceState.checkIfOnline : start`);
    const apiOk = await checkApi();
    const ocsOk = await checkOcs();
    isOnline.value = apiOk && ocsOk;
    Logger.info(`DeviceState.ts : isOnline ? api ${apiOk} ocs ${ocsOk}`);
  }

  async function checkApi(): Promise<boolean> {
    try {
      const data = await api.getHealth();
      return data.api.status == "ok" && data.database.status == "ok";
    } catch (e) {
      Logger.error("DeviceState.ts : there was a problem checking api status");
      return false;
    }
  }

  async function checkOcs(): Promise<boolean> {
    // could be better tà check version as well
    try {
      const response = await fetch(ocsURL + "/version");
      const data = await response.json();
      return data.version != undefined && data.hash != undefined;
    } catch (e) {
      return false;
    }
  }

  function startMonitoringOnlineState() {
    onlineStateMonitor.start();
  }

  function stopMonitoringOnlineState() {
    onlineStateMonitor.stop();
  }

  // see https://getbootstrap.com/docs/5.3/layout/breakpoints/#available-breakpoints
  function isSmall() {
    return window.screen.width < 768;
  }

  return {
    isOnline,
    isTouchDevice,
    startMonitoringOnlineState,
    stopMonitoringOnlineState,
    isSmall,
  };
});
