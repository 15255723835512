<script setup lang="ts">
import { provide, ref, type Ref } from "vue";
import {
  LegendConfiguratorSymbol,
  type ReadMode,
} from "./legendConfiguratorModalSymbols";
import LegendLib from "@/utils/LegendLib";
import type { Legend } from "@/stores/UserState";
import Modal from "bootstrap/js/dist/modal";

const legend = ref<Legend>();
const modalElem = ref();
const readMode: Ref<ReadMode> = ref("readonly");

function show(plegend: Legend, mode: ReadMode) {
  readMode.value = mode;
  legend.value = plegend;
  Modal.getOrCreateInstance(modalElem.value).show();
}

function hide() {
  Modal.getOrCreateInstance(modalElem.value).hide();
}

provide(LegendConfiguratorSymbol, {
  show,
  hide,
});
</script>

<template>
  <div
    class="modal fade"
    ref="modalElem"
    tabindex="-1"
    aria-hidden="true"
    role="dialog"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">
            Configuration légende : {{ legend?.name }}
          </h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body" v-if="legend != undefined">
          <div class="mt-4 mb-4">
            <h3>Désordres</h3>
            <ul class="list-group list-group-horizontal flex-wrap">
              <li
                v-for="config in legend?.note_configs"
                :key="config.name"
                class="list-group-item d-inline-flex justify-content-between align-items-center"
              >
                {{ config.name }} ({{ config.label || "" }})
                <span
                  class="color-circle"
                  :style="{
                    backgroundColor: LegendLib.getComputedColor(config, legend),
                  }"
                  >{{ " " }}</span
                >
              </li>
            </ul>
          </div>
          <div class="mt-4 mb-4">
            <h3>Calques</h3>
            <ul class="list-group list-group-horizontal flex-wrap">
              <li
                v-for="config in legend?.layer_configs"
                :key="
                  typeof config.name === 'string' ? config.name : config.name[0]
                "
                class="list-group-item d-inline-flex justify-content-between align-items-center"
              >
                {{ config.name }}
                <span
                  class="color-circle"
                  :style="{
                    backgroundColor: LegendLib.getLayerColor(
                      config.name,
                      legend
                    ),
                  }"
                  >{{ " " }}</span
                >
              </li>
            </ul>
          </div>
          <div class="mt-4 mb-4">
            <h3>Styles de texte</h3>
            <ul class="list-group list-group-horizontal flex-wrap">
              <li
                v-for="config in legend?.scale_configs"
                :key="config.name"
                class="list-group-item d-inline-flex justify-content-between align-items-center"
              >
                {{ config.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <!--button type="button" class="btn btn-primary">Save changes</button-->
        </div>
      </div>
    </div>
  </div>
  <slot></slot>
</template>

<style>
.color-circle {
  width: 10px;
  height: 10px;
  border: 1px solid black;
  display: inline-block;
  margin: 5px;
}
</style>
