import type { Color } from "./ColorDefBuilder";
import OdaGeometryUtils from "./odaGeometry.utils";
import { TextStyleBuilder, type Textstyle } from "./TextStyleBuilder";
import { TEMP_LAYER, type DRViewer } from "@/open-cloud/DRViewer";
import Toolbox from "./ODAToolbox";
import type { Model } from "./ModelBuilder";
import type { DRLineWeightType } from "../types/lineweight.type";
import { GeometryBuilder, type PolygonData } from "./GeometryBuilder";
import { TextBuilder } from "./TextBuilder";
import { Logger } from "@/logger";
import type { Extent } from "../types/oda.types";
import { InsertBuilder } from "./InsertBuilder";
import { PhotoMarker } from "../photoMarker";
import type { GeometryDataTyped } from "./BlockBuilder";

export interface EntityProps {
  model?: Model["pseudo"];
  color: Color;
  layername?: string;
  linetypename?: string;
  transparency?: {
    all?: number;
    edge?: number;
    face?: number;
  };
  linetypescale?: number;
  lineweight?: DRLineWeightType;
  textstyle?: Textstyle;
  textsize?: number;
}

const ENTITY_DEFAULT_LW: DRLineWeightType = "kInherited";

export class EntityBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  static getTextSizeFromProps(props: EntityProps): number {
    return props.textsize || props.textstyle?.size || 0.2;
  }

  // Gets the model of an entId
  // Returns the model or null

  getModel(entId: VisualizeJS.OdTvEntityId): VisualizeJS.TvModel | null {
    let handle: string;
    if (entId.getType() === 1) {
      const ent = entId.openObject();
      handle = ent.getDatabaseHandle();
      ent.delete();
    } else if (entId.getType() === 2) {
      const insert = entId.openObjectAsInsert();
      handle = insert.getHandle();
      insert.delete();
    } else {
      return null;
    }
    const iter = this.visViewer.getModelIterator();
    for (; !iter.done(); iter.step()) {
      const model: VisualizeJS.TvModel = iter.getModel();
      const result = model.findEntity(handle);
      if (!result.isNull()) {
        result.delete();
        iter.delete();
        return model;
      } else {
        result.delete();
        model.delete();
      }
    }
    iter.delete();
    return null;
  }

  removeByHandleIfTemp(handle: string) {
    const model = this.viewer.modelBuilder.findModel("ACTIVE");
    const entId = model?.findEntity(handle);
    if (!entId || entId.isNull()) return;
    const layername = EntityBuilder.getLayerName(entId);
    if (layername === TEMP_LAYER.name) {
      model?.removeEntity(entId);
    }
    Toolbox.deleteAll([entId, model]);
  }

  removeByHandlesIfTemp(handles: string[]) {
    for (const handle of handles) {
      this.removeByHandleIfTemp(handle);
    }
  }

  static getLayerName(entId: VisualizeJS.OdTvEntityId): string {
    let layerId: VisualizeJS.OdTvLayerId;
    if (entId.getType() === 2) {
      const insert = entId.openObjectAsInsert();
      layerId = insert.getLayer();
      insert.delete();
    } else {
      const ent = entId.openObject();
      layerId = ent.getLayer();
      ent.delete();
    }
    if (layerId.isNull()) {
      return "ZeroLayerName";
    }
    const layer = layerId.openObject();
    const name = layer.getName();
    layer.delete();
    layerId.delete();
    return name;
  }

  getLayerNameByHandle(handle: string): string {
    const entId = this.viewer.modelBuilder.getEntityByHandle(handle);
    if (!entId || entId.isNull()) return "";
    const name = EntityBuilder.getLayerName(entId);
    entId.delete();
    return name;
  }

  // checks is entity is in temp layer
  static isInTempLayer(entId: VisualizeJS.OdTvEntityId): boolean {
    const name = EntityBuilder.getLayerName(entId);
    return name === TEMP_LAYER.name;
  }

  // Changes the design properties of an entity
  // It sets the properties based on the config provided

  setProperties(entId: VisualizeJS.OdTvEntityId, props: EntityProps) {
    if (props.layername) this.setLayerByName(entId, props.layername);
    if (entId.getType() === 1) {
      const ent = entId.openObject();
      if (props.color) this.setColor(entId, props.color);
      if (props.linetypename) this.setLinetype(ent, props.linetypename);
      if (props.linetypescale) this.setLinetypescale(ent, props.linetypescale);
      this.setLineweight(ent, props.lineweight);
      // if it is a photo marker or it is hatched, scale it
      const scale = EntityBuilder.computeScale(props);
      if (scale) {
        if (PhotoMarker.isPhotoMarker(entId)) {
          this.viewer.photoMarker?.scaleMarker(entId, scale);
        } else if (this.viewer.shellBuilder.isHatched(entId)) {
          this.viewer.shellBuilder.setPatternScale(entId, scale);
        }
      }

      if (props.textstyle) this.setTextstyle(ent, props.textstyle);
      if (props.textsize) this.setTextsize(ent, props.textsize);
      // has to do this because if textsize is not set, alignment does not work properly if textsiz is not set
      else if (props.textstyle?.size)
        this.setTextsize(ent, props.textstyle?.size);
      if (props.transparency) this.setTranparency(ent, props.transparency);
      ent.delete();
    } else if (entId.getType() === 2) {
      const insert = entId.openObjectAsInsert();
      if (props.color) this.setColor(entId, props.color);
      // if it is a photo marker, scale it
      const scale = EntityBuilder.computeScale(props);
      if (scale && PhotoMarker.isPhotoMarker(entId)) {
        this.viewer.photoMarker?.scaleMarker(entId, scale);
      }
      const itr = insert.getSubEntitiesIterator();
      Toolbox.iterateBase(
        itr,
        (entityId: VisualizeJS.OdTvEntityId) => {
          this.setProperties(entityId, props);
        },
        (itr) => itr.getEntity()
      );
      itr.delete();
      insert.delete();
    } else {
      Logger.debug("EntityBuilder.setProperties unhandled type", props);
    }
  }

  // Logs the props of the entity (ent and insert) for debugging

  logEntityProperties(entId: VisualizeJS.OdTvEntityId) {
    Logger.debug("logEntityProperties");
    const model = this.getModel(entId);
    if (model) {
      Logger.debug("model", model.getName());
    } else {
      Logger.warn("no model");
    }
    model?.delete();
    let ent;
    if (entId.getType() === 1) {
      ent = entId.openObject();
      Logger.debug("name", ent.getName());
      Logger.debug("type: entity 1");
      Logger.debug("handle", ent.getDatabaseHandle());
      const colorDef = ent.getColor(this.visLib.GeometryTypes.kAll);
      this.viewer.colorDefBuilder.logProps(colorDef);
      colorDef.delete();
      const layerId = ent.getLayer();
      if (!layerId.isNull()) {
        this.viewer.layerBuilder.logLayerProps(layerId);
      } else {
        Logger.debug("layer is null");
      }
      layerId.delete();
      const textstyle = ent.getTextStyle();
      this.viewer.textStyleBuilder?.logTextStyleDefProperties(textstyle);
      textstyle.delete();
      this.viewer.lineweightBuilder.logLineweight(entId);
      const visDef = ent.getVisibility();
      Logger.debug("visDef type : ", visDef.getType());
      const itr = ent.getGeometryDataIterator();
      GeometryBuilder.iterateGeometries(
        itr,
        (geomId: VisualizeJS.OdTvGeometryDataId) => {
          GeometryBuilder.logProperties(geomId);
          geomId.delete();
        }
      );
      itr.delete();
      ent.delete();
    } else if (entId.getType() === 2) {
      ent = entId.openObjectAsInsert();
      Logger.debug("name", ent.getName());
      Logger.debug("type: insert 2");
      Logger.debug("handle", ent.getHandle());
      const colorDef = ent.getColor();
      this.viewer.colorDefBuilder.logProps(colorDef);
      colorDef.delete();
      Logger.debug("Position", ent.getPosition());
      Logger.debug("Rotation", ent.getPosition());
      Logger.debug("Normal", ent.getNormal());
      const layerId = ent.getLayer();
      if (!layerId.isNull()) {
        this.viewer.layerBuilder.logLayerProps(layerId);
      } else {
        Logger.debug("layer is null");
      }
      layerId.delete();
      const blockId = ent.getBlock();
      this.viewer.blockBuilder.log(blockId);
      blockId.delete();
      ent.delete();
    }
  }

  // Sets the colordef of the entity based on color param
  // color can be :
  // kByLayer, kByBlock, (R,G,B)
  // returns true if it worked
  // if color value is wrong, no changes are done to entity and it returns false

  protected setColor(entId: VisualizeJS.OdTvEntityId, color: string) {
    let ent;
    let colorDef: VisualizeJS.OdTvColorDef;
    if (entId.getType() === 1) {
      ent = entId.openObject();
      colorDef = ent.getColor(this.visLib.GeometryTypes.kAll);
      this.viewer.colorDefBuilder.setColor(colorDef, color);
      ent.setColor(colorDef, this.visLib.GeometryTypes.kAll);
      colorDef.delete();
      ent.delete();
    } else if (entId.getType() === 2) {
      // Setting the colorDef of an insert does not change anything
      // Color is linked to the ref block
      // ent = entId.openObjectAsInsert();
      // ent.setColor(colorDef);
    }
  }

  /**
   * Sets the layer of the entity or insert to the layer named layername
   * Returns true if the layer was found and set, false otherwise
   *
   *  */

  protected setLayerByName(
    entId: VisualizeJS.OdTvEntityId,
    layername: EntityProps["layername"]
  ) {
    if (layername) {
      const layerId = this.viewer.layerBuilder?.findLayer(layername);
      if (layerId) {
        this.setLayer(entId, layerId);
      } else {
        Logger.warn(
          `EntityBuilder.setLayer : can't find layer with name ${layername}`
        );
      }
    }
  }

  protected setLayer(
    entId: VisualizeJS.OdTvEntityId,
    layerId: VisualizeJS.OdTvLayerId
  ) {
    const ent = EntityBuilder.openEntityId(entId);
    ent.setLayer(layerId);
    ent.delete();
  }

  // use set Properties to get recursivity over sub entities
  setLayerByHandle(handle: string, layername: EntityProps["layername"]) {
    const entId = this.viewer.modelBuilder.getEntityByHandle(handle);
    if (!entId || entId.isNull()) {
      entId?.delete();
      return;
    }
    this.setProperties(entId, {
      layername,
    });
    entId.delete();
  }

  setLayerByHandles(handles: string[], layername: EntityProps["layername"]) {
    for (const handle of handles) {
      this.setLayerByHandle(handle, layername);
    }
  }

  // Sets the linetype of the entity
  // Returns true if the linetype exists and was set, false otherwise

  protected setLinetype(
    ent: VisualizeJS.OdTvEntity,
    linetypename: EntityProps["linetypename"]
  ) {
    if (linetypename) {
      const linetypedef = ent.getLinetype(this.visLib.GeometryTypes.kAll);
      if (this.visLib.LinetypePredefined[linetypename]) {
        linetypedef.setPredefinedLinetype(
          this.visLib.LinetypePredefined[linetypename]
        );
        ent.setLinetype(linetypedef, this.visLib.GeometryTypes.kAll);
      }
      linetypedef.delete();
    }
  }

  // Sets the linetype scale of the entity
  // Returns the LinetypeScale of the entity

  protected setLinetypescale(
    ent: VisualizeJS.OdTvEntity,
    linetypescale: EntityProps["linetypescale"]
  ) {
    if (linetypescale) {
      ent.setLinetypeScale(linetypescale, this.visLib.GeometryTypes.kAll.value);
    }
  }

  // Sets the lineweight of the entity
  // Returns true if the lineweight exists and was set, false otherwise
  //

  protected setLineweight(
    ent: VisualizeJS.OdTvEntity,
    lineweight: EntityProps["lineweight"] = ENTITY_DEFAULT_LW
  ) {
    const lwDef = ent.getLineWeight(this.visLib.GeometryTypes.kAll);
    this.viewer.lineweightBuilder.setLineweight(lwDef, lineweight);
    ent.setLineWeight(lwDef, this.visLib.GeometryTypes.kAll.value);
    lwDef.delete();
  }

  // Sets the tranparency of the whole entity and or part of it.

  protected setTranparency(
    ent: VisualizeJS.OdTvEntity,
    transparency: EntityProps["transparency"]
  ) {
    if (transparency && transparency.all) {
      const transparencyDef = ent.getTransparency(
        this.visLib.GeometryTypes.kAll
      );
      transparencyDef.setValue(transparency.all);
      ent.setTransparency(transparencyDef, this.visLib.GeometryTypes.kAll);
      transparencyDef.delete();
    }
    if (transparency && transparency.edge) {
      // FIXME : does not work with kEdges as GeometryTypes and it should
      const transparencyDef = ent.getTransparency(
        this.visLib.GeometryTypes.kPolylines
      );
      transparencyDef.setValue(transparency.edge);
      ent.setTransparency(
        transparencyDef,
        this.visLib.GeometryTypes.kPolylines
      );
      transparencyDef.delete();
    }
    if (transparency && transparency.face) {
      const transparencyDef = ent.getTransparency(
        this.visLib.GeometryTypes.kFaces
      );
      transparencyDef.setValue(transparency.face);
      ent.setTransparency(transparencyDef, this.visLib.GeometryTypes.kFaces);
      transparencyDef.delete();
    }
  }

  // Sets the text style of the entity based on the textstylename provided
  // Returns true if textstylename was found and set, false otherwise
  // if textsize is not set to 0, textsize is prior to texstyle

  protected setTextstyle(
    ent: VisualizeJS.OdTvEntity,
    textstyle: EntityProps["textstyle"]
  ): boolean {
    if (textstyle) {
      const textStyleId =
        this.viewer.textStyleBuilder.findTextstyle(textstyle) ||
        this.viewer.textStyleBuilder.getDefaultTextstyle();
      const textstyleDef = ent.getTextStyle();
      textstyleDef.setTextStyle(textStyleId);
      ent.setTextStyle(textstyleDef);
      textstyleDef.delete();
      textStyleId.delete();
      return true;
    }
    return false;
  }

  // Sets the size of the text element to number
  // 0 disables textsize
  // Returns textDataId[], empty [] if no text data was found

  protected setTextsize(
    ent: VisualizeJS.OdTvEntity,
    textsize: Required<EntityProps>["textsize"]
  ) {
    const textGeomIds = EntityBuilder.filterTexts(ent);
    textGeomIds.map((geomId) => {
      const text = geomId.openAsText();
      text.setTextSize(textsize);
      text.delete();
      geomId.delete();
    });
  }

  // Filters the text Geometries in the entity
  // Returns geomId[] or empty table otherwise

  static filterTexts(
    ent: VisualizeJS.OdTvEntity
  ): VisualizeJS.OdTvGeometryDataId[] {
    const iter = ent.getGeometryDataIterator();
    const textIds = [];
    for (; !iter.done(); iter.step()) {
      const geomId = iter.getGeometryData();
      if (TextBuilder.isTextGeometry(geomId)) textIds.push(geomId);
      else geomId.delete();
    }
    iter.delete();
    return textIds;
  }

  static filterNonTexts(
    ent: VisualizeJS.OdTvEntity
  ): VisualizeJS.OdTvGeometryDataId[] {
    const iter = ent.getGeometryDataIterator();
    const textIds = [];
    for (; !iter.done(); iter.step()) {
      const geomId = iter.getGeometryData();
      if (!TextBuilder.isTextGeometry(geomId)) textIds.push(geomId);
      else geomId.delete();
    }
    iter.delete();
    return textIds;
  }
  /**
   * Return the proprty textsizes of the in an entity
   * Careful : if the size is defined by the textstyle, it does not return the size
   * @param entId
   * @returns number[]
   */
  getTextsizes(entId: VisualizeJS.OdTvEntityId): number[] {
    const geomIds = EntityBuilder.filterTexts(entId.openObject());
    const textsizes: number[] = [];
    for (const geomId of geomIds) {
      if (geomId.getType() === this.visLib.OdTvGeometryDataType.kText.value) {
        const text = geomId.openAsText();
        textsizes.push(text.getTextSize());
        text.delete();
      }
      geomId.delete();
    }
    return textsizes;
  }

  /**
   * Return the Texstyle and textsize props of the entity for all textdata
   * @returns EntityProps[] ()
   */
  getTextProps(
    entId: VisualizeJS.OdTvEntityId
  ): Required<Pick<EntityProps, "textsize" | "textstyle">>[] {
    const geomIds = EntityBuilder.filterTexts(entId.openObject());
    const textsizes: Required<Pick<EntityProps, "textsize" | "textstyle">>[] =
      [];
    for (const geomId of geomIds) {
      if (geomId.getType() === this.visLib.OdTvGeometryDataType.kText.value) {
        const text = geomId.openAsText();
        const textstyleId = text.getTextStyle();
        if (!TextStyleBuilder.isNull(textstyleId)) {
          textsizes.push({
            textstyle: this.viewer.textStyleBuilder.getTextstyle(textstyleId),
            textsize: text.getTextSize(),
          });
        }
        textstyleId.delete();
        text.delete();
      }
      geomId.delete();
    }
    return textsizes;
  }

  getTextExtent(entId: VisualizeJS.OdTvEntityId): VisualizeJS.Extents3d {
    const ent = entId.openObject();
    const geomIds = EntityBuilder.filterTexts(ent);
    const ext = this.viewer.textBuilder.getTextExtent(geomIds);
    Toolbox.deleteAll([...geomIds, ent]);
    return ext;
  }

  /**
   * Returns true if ALL the geometries of an entity are texts,
   */
  static isOnlyText(entId: VisualizeJS.OdTvEntityId): boolean {
    if (entId.getType() != 1) return false;
    const ent = entId.openObject();
    const itr = ent.getGeometryDataIterator();
    let isOnlyText = true;
    const cb = (geomId: VisualizeJS.OdTvGeometryDataId) => {
      if (!TextBuilder.isTextGeometry(geomId)) {
        isOnlyText = false;
      }
      geomId.delete();
    };
    GeometryBuilder.iterateGeometries(itr, cb);
    ent.delete();
    itr.delete();
    return isOnlyText;
  }
  /**
   * designers use scales relative to textsize.
   * returns textsize if it is set, then texstyle.size if it is set
   * undefined if none is set
   * @param props properties with textsize or textstyle set
   * @returns the scale
   */
  static computeScale(
    props: Pick<EntityProps, "textsize" | "textstyle">
  ): number | undefined {
    if (props.textsize) {
      return props.textsize;
    } else if (props.textstyle?.size) {
      return props.textstyle?.size;
    } else {
      return undefined;
    }
  }
  // returns first text geometry content
  static getTextContent(entId: VisualizeJS.OdTvEntityId): string {
    if (entId.getType() === 1) {
      const ent = entId.openObject();
      const geometries = EntityBuilder.filterTexts(ent);
      let content = "";
      if (geometries.length) {
        content = TextBuilder.getTextContent(geometries[0]);
      }
      Toolbox.deleteAll([ent, ...geometries]);
      return content;
    } else {
      Logger.warn("EntityBuilder.getTextContent : entity type is not 1");
    }
    return "";
  }
  // edit first geomety content
  static editText(entId: VisualizeJS.OdTvEntityId, newContent: string) {
    if (entId.getType() === 1) {
      const ent = entId.openObject();
      const geometries = EntityBuilder.filterTexts(ent);
      if (geometries.length) {
        TextBuilder.edit(geometries[0], newContent);
      }
    } else {
      Logger.warn("EntityBuilder.editText : entity type is not 1");
    }
  }

  static countTextGeometries(entId: VisualizeJS.OdTvEntityId): number {
    if (entId.getType() === 1) {
      const ent = entId.openObject();
      const geometries = EntityBuilder.filterTexts(ent);
      const count = geometries.length;
      Toolbox.deleteAll([...geometries, ent]);
      return count;
    } else {
      Logger.warn("EntityBuilder.editText : entity type is not 1");
      return 0;
    }
  }

  /**
   * Check if 2 entities are equal by handle
   * @param entId1
   * @param entId2
   * @returns
   */
  static isHandleEqual(
    entId1: VisualizeJS.OdTvEntityId,
    entId2: VisualizeJS.OdTvEntityId
  ): boolean {
    if (entId1.getType() === 1 && entId2.getType() === 1) {
      const ent1 = entId1.openObject();
      const ent2 = entId2.openObject();
      const handle1 = ent1.getDatabaseHandle();
      const handle2 = ent2.getDatabaseHandle();
      ent1.delete();
      ent2.delete();
      return handle1 === handle2;
    } else if (entId1.getType() === 2 && entId2.getType() === 2) {
      const ent1 = entId1.openObjectAsInsert();
      const ent2 = entId2.openObjectAsInsert();
      const handle1 = ent1.getHandle();
      const handle2 = ent2.getHandle();
      ent1.delete();
      ent2.delete();
      return handle1 === handle2;
    } else {
      return false;
    }
  }

  static getHandle(entId: VisualizeJS.OdTvEntityId): string {
    if (entId.getType() === 1) {
      const ent = entId.openObject();
      const handle = ent.getDatabaseHandle();
      ent.delete();
      return handle;
    } else if (entId.getType() === 2) {
      const ent = entId.openObjectAsInsert();
      const handle = ent.getHandle();
      ent.delete();
      return handle;
    } else {
      return "";
    }
  }

  static iterateEntities(
    iterator: VisualizeJS.OdTvEntitiesIterator,
    cb: (entityId: VisualizeJS.OdTvEntityId, index: number) => void
  ) {
    Toolbox.iterateBase(iterator, cb, (iterator) => iterator.getEntity());
  }

  // Modelling matrix is not applied to entity but to geomIds in order to treat text appart
  // This function first returns geometries matrix, if no geometries, returns text matrix
  // else returns entity matrix
  static getModelingMatrix(
    entId: VisualizeJS.OdTvEntityId
  ): VisualizeJS.Matrix3d {
    if (entId.getType() === 1) {
      const ent = entId.openObject();
      const geomIds = EntityBuilder.filterNonTexts(ent);
      const textIds = EntityBuilder.filterTexts(ent);
      let matrix: VisualizeJS.Matrix3d;
      if (geomIds.length) {
        const geom = geomIds[0].openObject();
        matrix = geom.getModelingMatrix();
        geom.delete();
      } else if (textIds.length) {
        const geom = textIds[0].openObject();
        matrix = geom.getModelingMatrix();
        geom.delete();
      } else {
        matrix = ent.getModelingMatrix();
      }
      Toolbox.deleteAll([...geomIds, ...textIds, ent]);
      return matrix;
    } else if (entId.getType() === 2) {
      const insert = entId.openObjectAsInsert();
      const matrix = insert.getBlockTransform();
      insert.delete();
      return matrix;
    } else {
      throw new TypeError("entity is unknow");
    }
  }

  static setModelingMatrix(
    entId: VisualizeJS.OdTvEntityId,
    matrix: VisualizeJS.Matrix3d
  ) {
    if (entId.getType() === 1) {
      const ent = entId.openObject();
      const geomIds = EntityBuilder.filterNonTexts(ent);
      const textIds = EntityBuilder.filterTexts(ent);
      GeometryBuilder.setModelingMatrix(geomIds, matrix);
      GeometryBuilder.setTextModelingMatrix(textIds, matrix);
      Toolbox.deleteAll(geomIds);
      Toolbox.deleteAll(textIds);
    } else if (entId.getType() === 2) {
      InsertBuilder.setBlockMatrix(entId, matrix);
    }
  }

  static appendTransform(
    targetEntityId: VisualizeJS.OdTvEntityId,
    transform: VisualizeJS.Matrix3d
  ) {
    const matrix = EntityBuilder.getModelingMatrix(targetEntityId);
    EntityBuilder.appendTransformWithBase(targetEntityId, transform, matrix);
    matrix.delete();
  }

  static appendTransformWithBase(
    targetEntityId: VisualizeJS.OdTvEntityId,
    transformMatrix: VisualizeJS.Matrix3d,
    baseMatrix: VisualizeJS.Matrix3d
  ) {
    const baseWithNewIncrement =
      OdaGeometryUtils.createNewMatrix(baseMatrix).preMultBy(transformMatrix);
    EntityBuilder.setModelingMatrix(targetEntityId, baseWithNewIncrement);
    baseWithNewIncrement.delete();
  }

  // return the extent of the entity
  static getAgnosticExtent(
    entId: VisualizeJS.OdTvEntityId
  ): VisualizeJS.Extents3d {
    let ext: VisualizeJS.Extents3d;
    if (entId.getType() === 2) {
      const ent = entId.openObjectAsInsert();
      // @ts-ignore not documented..
      ext = ent.getExtents().ext;
      ent.delete();
    } else {
      const ent = entId.openObject();
      ext = ent.getExtents();
      ent.delete();
    }
    return ext;
  }

  static decodeExtent(ext: VisualizeJS.Extents3d): Extent {
    return {
      center: ext.center(),
      min: ext.min(),
      max: ext.max(),
    };
  }

  static openEntityId(entityId: VisualizeJS.OdTvEntityId) {
    if (entityId.getType() === 2) return entityId.openObjectAsInsert();
    return entityId.openObject();
  }

  static appendCircleHandle(
    entity: VisualizeJS.OdTvEntityId,
    center: VisualizeJS.Point3,
    radius: number,
    rgb: [number, number, number], // fill color
    prgb: [number, number, number] // perimeter color
  ) {
    const circleId = GeometryBuilder.addCircle(entity, { center, radius });
    const geom = circleId.openObject();
    geom.setColor(...rgb);
    const circle = circleId.openAsCircle();
    circle.setFilled(true);
    const perimeterId = GeometryBuilder.addCircle(entity, { center, radius });
    const geom1 = perimeterId.openObject();
    geom1.setColor(...prgb);
    const circle1 = perimeterId.openAsCircle();
    circle1.setThickness(0.9);
    Toolbox.deleteAll([circle1, geom1, perimeterId, circle, geom, circleId]);
  }

  static appendTriangleHandle(
    entId: VisualizeJS.OdTvEntityId,
    center: VisualizeJS.Point3,
    radius: number,
    rgb: [number, number, number] // fill color
  ) {
    const topleft: VisualizeJS.Point3 = [
      center[0] - radius * 0.5,
      center[1] + radius * 0.866,
      center[2],
    ];
    const bottomLeft: VisualizeJS.Point3 = [
      center[0] - radius * 0.5,
      center[1] - radius * 0.866,
      center[2],
    ];
    const rightCenter: VisualizeJS.Point3 = [
      center[0] + radius,
      center[1],
      center[2],
    ];
    const data: PolygonData = {
      corners: [topleft, bottomLeft, rightCenter],
      isFilled: true,
    };
    const geomId = GeometryBuilder.addPolygon(entId, data);
    const geom = geomId.openObject();
    geom.setColor(...rgb);
    Toolbox.deleteAll([geom, geomId]);
  }

  setVisible(entId: VisualizeJS.OdTvEntityId, visible: boolean) {
    const ent = entId.openObject();
    const visDef = ent.getVisibility();
    if (visible) visDef.setVisible();
    else visDef.setInvisible();
    ent.setVisibility(visDef, this.visLib.GeometryTypes.kAll);
    Toolbox.deleteAll([ent, visDef]);
  }

  getCanvasExtent(entId: VisualizeJS.OdTvEntityId) {
    const ext = EntityBuilder.getAgnosticExtent(entId);
    const points = [ext.center(), ext.min(), ext.max()];
    ext.delete();
    return points.map((point) => this.viewer.toolbox.getCanvasCoord(point));
  }

  static getName(entityId: VisualizeJS.OdTvEntityId): string {
    const ent = EntityBuilder.openEntityId(entityId);
    const name = ent.getName();
    ent.delete();
    return name;
  }

  // allows to append geometries (rectangle, ellipse, text) to an entity.
  static appendGeometries(
    entId: VisualizeJS.OdTvEntityId,
    geometries: GeometryDataTyped[]
  ) {
    for (const geommetry of geometries) {
      switch (geommetry.type) {
        case "polyline":
          GeometryBuilder.addPolyline(entId, geommetry);
          break;
        case "text":
          TextBuilder.addText(entId, geommetry);
          break;
        case "circle":
          GeometryBuilder.addCircle(entId, geommetry);
          break;
        case "ellipse":
          GeometryBuilder.addEllipse(entId, geommetry);
          break;
        default:
          Logger.warn(`BlockBuilder : unknown geometry type`);
          break;
      }
    }
  }

  // WORK IN PROGRESS not entirely working right now
  exportGeometryDataTyped(
    entId: VisualizeJS.OdTvEntityId
  ): GeometryDataTyped[] {
    const ent = entId.openObject();
    const itr = ent.getGeometryDataIterator();
    const geometries: GeometryDataTyped[] = [];
    for (; !itr.done(); itr.step()) {
      const geomId = itr.getGeometryData();
      const geometry =
        this.viewer.geometryBuilder.exportGeometryDataTyped(geomId);
      if (geometry) geometries.push(geometry);
    }
    return geometries;
  }

  filterSubEntities(
    entId: VisualizeJS.OdTvEntityId
  ): VisualizeJS.OdTvGeometryDataId[] {
    const geomIds = [];
    const ent = entId.openObject();
    const itr = ent.getGeometryDataIterator();
    for (; !itr.done(); itr.step()) {
      const geomId = itr.getGeometryData();
      if (
        geomId.getType() === this.visLib.OdTvGeometryDataType.kSubEntity.value
      ) {
        geomIds.push(geomId);
      } else {
        geomId.delete();
      }
    }
    Toolbox.deleteAll([itr, ent]);
    return geomIds;
  }
}
