import type { DRViewer } from "../DRViewer";
import { v4 } from "uuid";
import {
  GeometryBuilder,
  type PolygonData,
  type PolylineData,
} from "./GeometryBuilder";
import Toolbox from "./ODAToolbox";
import { EntityBuilder } from "./EntityBuilder";
import OdaGeometryUtils from "./odaGeometry.utils";
const LEADER_PREFIX = "DRleader_";
const LEADER_NAME_RG = new RegExp(`${LEADER_PREFIX}`);
const TEXTSIZE_TO_FIRST_POINT_GAP_RATIO = 1;
const TEXTSIZE_TO_SECOND_POINT_GAP_RATIO = 2;
const TEXTSIZE_TO_HEAD_RATIO = 0.6;

export class LeaderBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  isLeader(entId: VisualizeJS.OdTvEntityId): boolean {
    const geomIds = this.viewer.entityBuilder.filterSubEntities(entId);
    if (geomIds.length) {
      const name = GeometryBuilder.getSubEntityName(geomIds[0]);
      Toolbox.deleteAll(geomIds);
      return LEADER_NAME_RG.test(name);
    } else {
      return false;
    }
  }

  static createLeaderName() {
    return `${LEADER_PREFIX}${v4()}`;
  }

  getLeaderByHandle(
    entId: VisualizeJS.OdTvEntityId,
    handle: string
  ): VisualizeJS.OdTvGeometryDataId | undefined {
    return this.viewer.geometryBuilder.getSubEntityByHandle(entId, handle);
  }

  addOrUpdateLeader(
    entId: VisualizeJS.OdTvEntityId,
    arrowTip: VisualizeJS.Point3,
    scale: number,
    handle?: string
  ): string {
    if (handle) {
      return this.updateLeader(entId, arrowTip, scale, handle);
    } else {
      return this.addLeader(entId, arrowTip, scale);
    }
  }

  updateLeader(
    entId: VisualizeJS.OdTvEntityId,
    arrowTip: VisualizeJS.Point3,
    scale: number,
    handle: string
  ): string {
    const subentId = this.getLeaderByHandle(entId, handle);
    if (!subentId) throw new TypeError("LeaderBuilder: can't find sub entity");
    GeometryBuilder.clearSubEntity(subentId);
    this._addContentToLeader(entId, subentId, arrowTip, scale);
    return handle;
  }

  addLeader(
    entId: VisualizeJS.OdTvEntityId,
    arrowTip: VisualizeJS.Point3,
    scale: number
  ): string {
    const ent = entId.openObject();
    const name = LeaderBuilder.createLeaderName();
    const subentId = ent.appendSubEntity(name);
    const subent = subentId.openAsSubEntity();
    const handle = subent.getDatabaseHandle();
    this._addContentToLeader(entId, subentId, arrowTip, scale);
    Toolbox.deleteAll([ent, subentId, subent]);
    return handle;
  }

  _addContentToLeader(
    entId: VisualizeJS.OdTvEntityId,
    subentId: VisualizeJS.OdTvGeometryDataId,
    arrowTip: VisualizeJS.Point3,
    scale: number
  ) {
    // apply same modelling matrix to sub ent
    const matrix = EntityBuilder.getModelingMatrix(entId);
    GeometryBuilder.setModelingMatrix([subentId], matrix);

    const ext = this.viewer.entityBuilder.getTextExtent(entId);
    const [start, middle, end] = LeaderBuilder.computeArrowFromExtents(
      ext,
      arrowTip,
      TEXTSIZE_TO_FIRST_POINT_GAP_RATIO * scale,
      TEXTSIZE_TO_SECOND_POINT_GAP_RATIO * scale
    );

    // end should be inverted by transform matrix in order to stay at pointer position
    const p = OdaGeometryUtils.createPoint3dFromArray(end);
    p.transformBy(matrix.invert());
    const invertedEnd = p.toArray() as VisualizeJS.Point3;

    // add body of leader
    const data: PolylineData = {
      points: [start, middle, invertedEnd],
    };

    GeometryBuilder.addPolylineToSub(subentId, data);

    // add head of leader
    const polygonData: PolygonData = {
      corners: LeaderBuilder.computeArrowHead(middle, invertedEnd, scale),
      isFilled: true,
    };
    GeometryBuilder.addPolygonToSub(subentId, polygonData);

    Toolbox.deleteAll([matrix, p]);
  }

  /**
   *
   * @param extents extent of text
   * @param arrowEndWCS the tip of the leader
   * @param gap
   * @param gap2
   * @returns
   */
  static computeArrowFromExtents(
    extents: VisualizeJS.Extents3d,
    arrowEndWCS: VisualizeJS.Point3,
    gap: number, // gap between text boundingbox and first arrow point
    gap2: number // gap between text boundingbox and second arrow point
  ): [VisualizeJS.Point3, VisualizeJS.Point3, VisualizeJS.Point3] {
    const [xMin, yMin] = extents.min();
    const [xMax, yMax] = extents.max();

    const xMiddleAxis = (xMax + xMin) / 2;
    let point1: VisualizeJS.Point3, point2: VisualizeJS.Point3;
    if (arrowEndWCS[0] >= xMiddleAxis) {
      point1 = [xMax + gap, (yMin + yMax) / 2, arrowEndWCS[2]];
      point2 = [xMax + gap2, (yMin + yMax) / 2, arrowEndWCS[2]];
    } else {
      point1 = [xMin - gap, (yMin + yMax) / 2, arrowEndWCS[2]];
      point2 = [xMin - gap2, (yMin + yMax) / 2, arrowEndWCS[2]];
    }
    return [point1, point2, arrowEndWCS];
  }

  static computeArrowHead(
    tail: VisualizeJS.Point3,
    head: VisualizeJS.Point3,
    size: number
  ) {
    const angle = Math.atan2(head[1] - tail[1], head[0] - tail[0]);

    const delta = (10 * Math.PI) / 180; // Define the width of the leader

    const radialPoint: VisualizeJS.Point3 = [
      head[0] - TEXTSIZE_TO_HEAD_RATIO * size,
      head[1],
      head[2],
    ];
    const firstPoint = Toolbox.rotatePoint(head, radialPoint, angle + delta);
    const secondPoint = Toolbox.rotatePoint(head, radialPoint, angle - delta);
    return [firstPoint, secondPoint, head];
  }
}
