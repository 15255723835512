import type { InjectionKey } from "vue";
import type { TextTarget } from "../canvas/Tools/TextInput/text.type";

export type ModalKeyboardProviderValue = {
  show: (target: TextTarget) => void;
  hide: () => void;
};

export const ModalKeyboardSymbol = Symbol(
  "Keyboard modal provider identifier"
) as InjectionKey<ModalKeyboardProviderValue>;
