import OneWayEntityDragger from "./OneWayEntityDragger";
import {
  checkIfCloseAndAddCursorStart,
  isExtremitiesClose,
} from "../composables/close.utils";
import { clearCursor } from "../composables/close.utils";
import { smooth3DPolyline } from "../composables/smooth.utils";
import HatchLibrary from "@/hatches/library";
import ShellBuilder from "@/open-cloud/builders/ShellBuilder";
import type { HatchProp } from "@/stores/UserState";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";

export default class FreeLineDragger extends OneWayEntityDragger {
  autoSelect = false;
  cursorId: VisualizeJS.OdTvEntityId | null = null;

  end(x: number, y: number) {
    if (!this.shadowId) return;
    let isClosed = false;
    if (this.viewer.closeContour) {
      isClosed = isExtremitiesClose(this.drawPoints, this.viewer);
    }
    if (this.viewer.smoothen) {
      this.drawPoints = smooth3DPolyline(this.drawPoints);
    }
    if (isClosed) this.closeContour();
    this.refreshShadowEntity();
    const ent = this.shadowId?.openObject();
    const geomId = ent?.appendPolyline(this.drawPoints);
    if (isClosed) {
      if (this.viewer.activeNoteConfig.hatch != undefined) {
        this.drawHatches(
          this.shadowId,
          this.drawPoints,
          this.viewer.activeNoteConfig.hatch
        );
      }
    }
    this._setNoteConfigProperties(this.shadowId);
    geomId?.delete();
    ent?.delete();
    super.end(x, y);
  }

  _updateFrame(): void {
    this.refreshShadowEntity();
    if (this.shadowId) {
      const ent = this.shadowId.openObject();
      const geomId = ent.appendPolyline(this.drawPoints);
      this._setNoteConfigProperties(this.shadowId);
      if (this.viewer.closeContour) {
        this.cursorId = checkIfCloseAndAddCursorStart(
          this.cursorId,
          this.drawPoints,
          this.viewer
        );
      }

      geomId.delete();
      ent.delete();
    }
  }

  /**
   * Closes the contour if the extremities are close
   */
  closeContour() {
    this.drawPoints.push(
      this.drawPoints[0],
      this.drawPoints[1],
      this.drawPoints[2]
    );
  }

  drawHatches(
    entId: VisualizeJS.OdTvEntityId,
    points: number[],
    hatch: HatchProp
  ) {
    // get last point, first point and second to calculate the turn direction used to determine face direction
    // it affects hatch direction
    const p1 = OdaGeometryUtils.getPointFromFlattenArray(points, -2);
    const p2 = OdaGeometryUtils.getPointFromFlattenArray(points, 1);
    const p3 = OdaGeometryUtils.getPointFromFlattenArray(points, 2);
    const turnDirection = OdaGeometryUtils.getTurnDirection([
      ...p1,
      ...p2,
      ...p3,
    ]);

    // flip hatches if user is turning clockwise
    const flipY = turnDirection === "Clockwise" ? true : false;
    const item = HatchLibrary.getByName(hatch.name, hatch.rotation, flipY);

    if (!item) return;

    const geomId = ShellBuilder.appendPolylineShell(entId, points);
    this.viewer.shellBuilder.setHatchPattern(geomId, item.hatches);
    geomId.delete();
  }

  clear() {
    this.cursorId = clearCursor(this.cursorId, this.viewer);
    super.clear();
  }
}
