import { TEMP_LAYER, type DRViewer } from "@/open-cloud/DRViewer";
import type { Color } from "./ColorDefBuilder";
import type { DRLineWeightType } from "../types/lineweight.type";
import { Logger } from "@/logger";

export type Layer = {
  name: string; //string[] for Regexp
  visibility?: boolean;
  color: Color;
  lineweight?: DRLineWeightType;
};
export type Layers = Layer[];

export const DEFAULT_LAYER_NAME = "ZeroLayerName";

const DEFAULT_LAYER_THICKNESS: DRLineWeightType = 2;
//const THRESHOLD_DIST_LW = 100000;

export class LayerBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  getLayers(): Layer[] {
    const layers: Layer[] = [];
    const layerItr: VisualizeJS.OdTvLayersIterator =
      this.visViewer.getLayersIterator();
    for (; !layerItr.done(); layerItr.step()) {
      const layerId = layerItr.getLayer();
      const layer = layerId.openObject();
      // Don't return internal temp layer
      if (layer.getName() != TEMP_LAYER.name) {
        layers.push({
          name: layer.getName(),
          visibility: layer.getVisible(),
          color: layer.getColor().getColor(),
          lineweight: layer.getLineWeight().getValue(),
        });
      }
      layerId.delete();
      layer.delete();
    }
    layerItr.delete();
    return layers;
  }

  toggleLayerVisibility(layer: Layer): boolean {
    const layerId = this.findLayer(layer.name);
    if (layerId) {
      const layerPtr = layerId.openObject();
      let visibility = layerPtr.getVisible();
      layerPtr.setVisible(!visibility);
      visibility = layerPtr.getVisible();
      layerId.delete();
      layerPtr.delete();
      return visibility;
    } else {
      Logger.warn(
        `LayerBuilder.toggleLayerVisibility() : could not toggle visibility ${JSON.stringify(
          layer
        )}`
      );
      return false;
    }
  }

  // Finds a layer having the layername as name
  // layername can be a string or a Regexp
  // Returns the layerId of the first match or null if nothing was found
  findLayer(layername: Layer["name"]): VisualizeJS.OdTvLayerId | null {
    const iter: VisualizeJS.OdTvLayersIterator =
      this.visViewer.getLayersIterator();
    for (; !iter.done(); iter.step()) {
      const layerId = iter.getLayer();
      const layer = layerId.openObject();
      const name = layer.getName();
      layer.delete();
      if (layername && typeof layername === "string" && layername == name) {
        iter.delete();
        return layerId;
      } else if (
        layername &&
        typeof layername !== "string" &&
        new RegExp(layername[0], layername[1]).test(name)
      ) {
        iter.delete();
        return layerId;
      }
    }
    iter.delete();
    return null;
  }

  setProperties(layerId: VisualizeJS.OdTvLayerId, props: Layer) {
    const layer = layerId.openObject();
    if (typeof props.name === "string") {
      layer.setName(props.name);
    }
    if (props.visibility != undefined) {
      layer.setVisible(props.visibility);
      layer.setTotallyInvisible(!props.visibility);
    }
    if (props.color) this.setColor(layerId, props.color);
    this.setLineweight(layerId, props.lineweight);
    layer.delete();
  }

  putLayer(props: Layer) {
    let layerId = this.findLayer(props.name);
    if (layerId == null) {
      layerId = this.visViewer.createLayer(
        props.name
      ) as VisualizeJS.OdTvLayerId; // ugly type
      this.setProperties(layerId, props);
    } else {
      this.setProperties(layerId, props);
    }
    layerId.delete();
  }

  // Sets the colordef of the entity based on color param
  // color must be a string as "R,G,B"
  setColor(layerId: VisualizeJS.OdTvLayerId, color: string) {
    const layer = layerId.openObject();
    const colorDef = layer.getColor();
    this.viewer.colorDefBuilder.setColor(colorDef, color);
    layer.setColor(colorDef);
    colorDef.delete();
    layer.delete();
  }

  setLineweight(
    layerId: VisualizeJS.OdTvLayerId,
    lw = DEFAULT_LAYER_THICKNESS
  ) {
    const layer = layerId.openObject();
    const lwDef = layer.getLineWeight();
    this.viewer.lineweightBuilder.setLineweight(lwDef, lw);
    layer.setLineWeight(lwDef);
    lwDef.delete();
    layer.delete();
  }

  logLayersProps() {
    const iter: VisualizeJS.OdTvLayersIterator =
      this.visViewer.getLayersIterator();
    for (; !iter.done(); iter.step()) {
      const layer = iter.getLayer();
      this.logLayerProps(layer);
      layer.delete();
    }
    iter.delete();
  }

  logLayerProps(layerId: VisualizeJS.OdTvLayerId) {
    const layer = layerId.openObject();
    Logger.debug("layername: ", layer.getName());
    const colorDef = layer.getColor();
    this.viewer.colorDefBuilder.logProps(colorDef);
    colorDef.delete();
    layer.delete();
  }
}
