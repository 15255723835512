import type { Legend, NoteConfig } from "@/stores/UserState";
import type { Layer } from "@/open-cloud/builders/LayerBuilder";

export type HexaColour = `#${string}`;
export default class LegendLib {
  static getComputedColor(note: NoteConfig, legend: Legend): HexaColour {
    if (note.props.color === "kByLayer" && note.props.layername) {
      return LegendLib.getLayerColor(note.props.layername, legend);
    } else if (note.props.color == "kByBlock") {
      // TO be added when adding blocks
      return "#000000";
    } else {
      return LegendLib.toHex(note.props.color);
    }
  }
  // decode a string as "R,G,B" to [R,G,B]
  static toRGB(color: string): [number, number, number] | undefined {
    const matches = color.match(/\d{1,3}/g);
    if (matches && matches.length === 3) {
      const [R, G, B] = matches.map((str) => Number(str));
      return [R, G, B];
    } else {
      return undefined;
    }
  }

  static toHex(color: string): HexaColour {
    const c = LegendLib.toRGB(color);
    if (!c) return "#000000";
    return ("#" +
      LegendLib._componentToHex(c[0]) +
      LegendLib._componentToHex(c[1]) +
      LegendLib._componentToHex(c[2])) as HexaColour;
  }

  static _componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  static getLayerColor(layername: Layer["name"], legend: Legend): HexaColour {
    const layer = legend.layer_configs.find(
      (layer) => layer.name === layername
    );
    if (!layer || !layer.color) return "#000000";
    return LegendLib.toHex(layer.color);
  }
}
