<script setup lang="ts">
import { Facade } from "@/open-cloud/Facade";
import type { TextTarget } from "../Tools/TextInput/text.type";
import { useModalKerboard } from "@/components/composable/useModalKeyboard";
import type { ExposedScaleMenu } from "./floating.type";
import { computed, ref, type Ref } from "vue";

const handle = ref("");
const target: Ref<TextTarget> = ref({
  message: "",
  update,
});

const keyboard = useModalKerboard();

function update(newContent: string): void {
  Facade.editText(handle.value, newContent);
  target.value.message = Facade.getText(handle.value);
}

function openModal() {
  keyboard?.show(target.value);
}

function updateHandle(targets: string[]) {
  handle.value = targets[0];
  target.value.message = Facade.getText(handle.value);
}

defineExpose<ExposedScaleMenu>({
  update: updateHandle,
});

const show = computed<boolean>(() => {
  return Facade.isScalable(handle.value);
});
</script>

<template>
  <button
    v-show="show"
    type="button"
    class="btn btn-secondary"
    @click="openModal"
  >
    <i class="bi bi-pencil-square"></i>
  </button>
</template>
