<script setup lang="ts">
import Dragger from "./Dragger.vue";
import { DraggerName } from "@/open-cloud/draggers/draggers.type";
import type { DraggerConfig } from "@/open-cloud/draggers/draggers.type";
import CloseContourBtn from "./options/CloseContourBtn.vue";
import AddLabelBtn from "./options/AddLabelBtn.vue";
import { type Ref, ref, onMounted, watch } from "vue";
import DraggerMenuState from "@/stores/DraggerMenuState";
import Dropdown from "bootstrap/js/dist/dropdown";
import { Logger } from "@/logger";
import { delay } from "lodash";
import PhotoMarkerInput from "./options/PhotoMarkerInput.vue";
import SmoothenBtn from "./options/SmoothenBtn.vue";

const freehandToggler = ref<HTMLAnchorElement>();
const polylineToggler = ref<HTMLAnchorElement>();
const photoToggler = ref<HTMLAnchorElement>();
const ellipseToggler = ref<HTMLAnchorElement>();
const rectangleToggler = ref<HTMLAnchorElement>();
const straightLineToggler = ref<HTMLAnchorElement>();

type DraggerElement = {
  name: DraggerName;
  toggler: Ref<HTMLAnchorElement | undefined>;
  dropdown?: Dropdown;
};

const draggerElements: DraggerElement[] = [
  { name: DraggerName.DrFreeLine, toggler: freehandToggler },
  { name: DraggerName.DrPolyline, toggler: polylineToggler },
  { name: DraggerName.DrEllipse, toggler: ellipseToggler },
  { name: DraggerName.DrRectangle, toggler: rectangleToggler },
  { name: DraggerName.DrStraightLine, toggler: straightLineToggler },
  { name: DraggerName.PhotoMarker, toggler: photoToggler },
];

function hideAllBut(showed: DraggerConfig) {
  draggerElements.forEach((elem) => {
    if (elem.name != showed.name && elem.dropdown) {
      elem.dropdown.hide();
    }
  });
}

function updateOptions() {
  const idx = draggerElements.findIndex(
    (elem) => elem.name === DraggerMenuState.activeDragger.name
  );
  if (idx != -1) {
    hideAllBut(DraggerMenuState.activeDragger);
  } else {
    hideAllBut({ name: DraggerName.NoDragger });
  }
}

function initOptions() {
  // init dropdowms instances
  for (const elem of draggerElements) {
    if (elem.toggler.value) {
      elem.dropdown = Dropdown.getOrCreateInstance(elem.toggler.value);
    } else {
      Logger.warn(`DraggerMenu.ts : toggle of ${elem.name} is not defined`);
    }
  }

  delay(() => {
    // Show menu if active on start
    for (const elem of draggerElements) {
      if (elem.name === DraggerMenuState.activeDragger.name) {
        elem.dropdown?.show();
        return;
      }
    }
  }, 100);
}

function activateDragger(config: DraggerConfig) {
  Logger.info(`Dragger.vue : activating dragger ${config.name}`);
  DraggerMenuState.setActiveDragger(config);
}

onMounted(() => {
  initOptions();
  delay(updateOptions, 300); // bs or popper take time to init and then the menu would be shifted a little
});

watch(
  () => DraggerMenuState.activeDragger.name,
  () => {
    updateOptions();
  }
);
</script>

<template>
  <div class="btn-group-vertical">
    <div class="btn-group-vertical dropend">
      <a
        ref="freehandToggler"
        class="btn btn-secondary dr-option-menu"
        role="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="false"
        aria-expanded="false"
      >
        <Dragger
          @click="activateDragger({ name: DraggerName.DrFreeLine })"
          :config="{ name: DraggerName.DrFreeLine }"
          icon="pencil"
          tooltip-title="Crayon"
          tooltip-text="Le crayon permet d'écrire ou de dessiner à main levée comme sur le papier."
        />
      </a>
      <div class="dropdown-menu dr-option-menu">
        <div class="btn-group-vertical">
          <CloseContourBtn />
          <SmoothenBtn />
        </div>
      </div>
    </div>
    <div class="btn-group dropend">
      <a
        ref="polylineToggler"
        class="btn btn-secondary dr-option-menu"
        role=" button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="false"
        aria-expanded="false"
      >
        <Dragger
          @click="activateDragger({ name: DraggerName.DrPolyline })"
          :config="{ name: DraggerName.DrPolyline }"
          icon="vector-pen"
          tooltip-title="Polyligne"
          tooltip-text="L'outil polyline permet de dessiner des polylignes. Faites glisser les sommets un à un sur l'écran."
        />
      </a>
      <div class="dropdown-menu dr-option-menu">
        <div class="btn-group-vertical">
          <CloseContourBtn />
        </div>
      </div>
    </div>
    <div class="btn-group dropend">
      <a
        ref="ellipseToggler"
        class="btn btn-secondary dr-option-menu"
        role=" button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="false"
        aria-expanded="false"
      >
        <Dragger
          @click="activateDragger({ name: DraggerName.DrEllipse })"
          :config="{ name: DraggerName.DrEllipse }"
          icon="circle"
          tooltipText="Dessinez une ellipse en tirant les deux angles opposés. L'élément dessiné prend les propriétés du désordres sélectionnés."
          tooltipTitle="Ellipse"
        />
      </a>
      <div class="dropdown-menu dr-option-menu">
        <div class="btn-group-vertical">
          <AddLabelBtn />
        </div>
      </div>
    </div>
    <div class="btn-group dropend">
      <a
        ref="rectangleToggler"
        class="btn btn-secondary dr-option-menu"
        role=" button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="false"
        aria-expanded="false"
      >
        <Dragger
          @click="activateDragger({ name: DraggerName.DrRectangle })"
          :config="{ name: DraggerName.DrRectangle }"
          icon="square"
          tooltipText="Dessinez un rectangle en tirant les deux angles opposés. L'élément dessiné prend les propriétés du désordres sélectionnés."
          tooltipTitle="Rectangle"
        />
      </a>
      <div class="dropdown-menu dr-option-menu">
        <div class="btn-group-vertical">
          <AddLabelBtn />
        </div>
      </div>
    </div>
    <div class="btn-group dropend">
      <a
        ref="straightLineToggler"
        class="btn btn-secondary dr-option-menu"
        role=" button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="false"
        aria-expanded="false"
      >
        <Dragger
          @click="activateDragger({ name: DraggerName.DrStraightLine })"
          :config="{ name: DraggerName.DrStraightLine }"
          icon="slash-lg"
          tooltipText="Dessinez une ligne droite en tirant les deux angles opposés. L'élément dessiné prend les propriétés du désordres sélectionnés."
          tooltipTitle="Ligne droite"
        />
      </a>
      <div class="dropdown-menu dr-option-menu">
        <div class="btn-group-vertical">
          <AddLabelBtn />
        </div>
      </div>
    </div>
    <div class="btn-group">
      <Dragger
        @click="activateDragger({ name: DraggerName.DrText })"
        :config="{ name: DraggerName.DrText }"
        icon="type"
        tooltipText="Ecrivez du texte dans le champ en bas de l'écran. Faites glisser ensuite le stylet sur l'écran pour positionner les éléments à l'écran."
        tooltipTitle="Texte"
      />
    </div>
    <div class="btn-group dropend">
      <a
        ref="photoToggler"
        class="btn btn-secondary dr-option-menu"
        role="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="false"
        aria-expanded="false"
      >
        <Dragger
          @click="activateDragger({ name: DraggerName.PhotoMarker })"
          :config="{ name: DraggerName.PhotoMarker }"
          icon="camera-fill"
          tooltipText="Tapez le numéro du marqueur photo de votre choix et ajoutez le en faisant glisser le stylet sur le plan. L'élément dessiné prend les propriétés du désordres sélectionnés."
          tooltipTitle="Marqueur Photo"
        />
      </a>
      <div class="dropdown-menu dr-option-menu">
        <div class="btn-group-vertical">
          <PhotoMarkerInput />
        </div>
      </div>
    </div>
    <div class="btn-group">
      <Dragger
        @click="activateDragger({ name: DraggerName.DrSelect })"
        :config="{ name: DraggerName.DrSelect }"
        icon="bounding-box-circles"
        tooltipText="
      Sélection d’entités de l’espace objet.
      </br>3 modes de sélection similaires à AutoCAD :
           <ul>
              <li>un seul objet, cliquez une fois dessus</li>
              <li>multiples dans la zone de sélection, dessinez la zone vers la droite</li>
              <li>multiples croisant la zone de sélection, dessinez la zone vers la gauche</li>
           </ul>"
        tooltipTitle="Selection"
      />
    </div>
  </div>
</template>

<style>
/**
Overwrite some btn option
*/
.dr-option-menu {
  min-width: 0 !important;
  padding: 0 !important;
}
</style>
