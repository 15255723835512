import type { DRViewer } from "@/open-cloud/DRViewer";
import { v4 } from "uuid";
import { EntityBuilder } from "./builders/EntityBuilder";
import OdaGeometryUtils from "./builders/odaGeometry.utils";
import { BlockBuilder, type BlockConfig } from "./builders/BlockBuilder";
import { photoMarkers } from "@/blocks/photomarker";

type MarkerData = {
  entityId: VisualizeJS.OdTvEntityId;
  name: string;
  count: number;
};

export type PhotoMarkerShape = "circle" | "large_arrow" | "thin_arrow";

const PHOTO_MARKER_NAME_PREFIX = "photo_marker__";
const PHOTO_MARKER_NAME_RG = new RegExp(`${PHOTO_MARKER_NAME_PREFIX}(\\d+)`);

/**
 * We call _Grid_ the grid around a selected entity along with its _Handles_ (colored circles user can grad to rotate/scale/translate).
 * While the user is rotating or resizing an entity, we display what would be the result, we called that temporary entity the _Ghost_ entity.
 */
export class PhotoMarker {
  markers: MarkerData[] = [];
  lastCount: number | null = null;
  static textSizeToShapeScaleFactor = 0.6;

  constructor(private readonly viewer: DRViewer) {}
  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }
  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  scanForMarkers() {
    const activeModel = this.viewer.modelBuilder.findModel("ACTIVE");
    this.markers = [];
    const itr = activeModel.getEntitiesIterator();
    EntityBuilder.iterateEntities(itr, (entityId) => {
      if (
        PhotoMarker.isPhotoMarker(entityId) &&
        !EntityBuilder.isInTempLayer(entityId)
      ) {
        const name = EntityBuilder.getName(entityId);
        const res = PHOTO_MARKER_NAME_RG.exec(name);
        if (!res) {
          //throw new Error("Found marker cannot be parsed for photo number");
        } else {
          const count = parseInt(res[1], 10);

          this.markers.push({
            count,
            entityId,
            name,
          });
        }
      }
      entityId.delete();
    });
    itr.delete();
    activeModel.delete();

    if (this.markers.length) {
      this.markers.sort((a, b) => a.count - b.count);
      this.lastCount = this.markers[this.markers.length - 1].count;
    }
  }

  dispose() {
    this.markers = [];
    this.lastCount = null;
  }

  static getPhotoMarkerName(count: string) {
    return `${PHOTO_MARKER_NAME_PREFIX}${count}_${v4()}`;
  }

  static isPhotoMarker(entityId: VisualizeJS.OdTvEntityId): boolean {
    const name = EntityBuilder.getName(entityId);
    return PHOTO_MARKER_NAME_RG.test(name);
  }

  scaleMarker(entId: VisualizeJS.OdTvEntityId, newScale: number) {
    // !!!! Block have initial scale defined in Block Config.
    // For entities, it is by default 1. We can' retrieve the block config the entity was
    // built with and then can't retrieve the initial scale. So it has to be 1
    let initialScale = 1;
    if (entId.getType() === 2) {
      initialScale = this.getInitialScale(entId);
    }
    const oldMatrix = EntityBuilder.getModelingMatrix(entId);
    const newMatrix = OdaGeometryUtils.reScaleMatrix(
      oldMatrix,
      newScale,
      initialScale
    );
    EntityBuilder.setModelingMatrix(entId, newMatrix);
  }

  // returns the initial scale defined in the block config
  getInitialScale(entId: VisualizeJS.OdTvEntityId): number {
    if (entId.getType() === 2) {
      const config = PhotoMarker.getMarkerConfig(entId);
      if (config) {
        return config.scale;
      } else {
        return 1;
      }
    } else if (entId.getType() === 1) {
      const propss = this.viewer.entityBuilder.getTextProps(entId);
      if (propss.length) {
        const tSize = EntityBuilder.computeScale(propss[0]);
        return tSize || 1;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }
  /**
   * Match block name with the name of an item of photo marker config array
   * @param insertId photo marker insert id
   * @returns the config
   */
  static getMarkerConfig(
    insertId: VisualizeJS.OdTvEntityId
  ): BlockConfig | undefined {
    const insert = insertId.openObjectAsInsert();
    const blockId = insert.getBlock();
    const name = BlockBuilder.getConfigName(blockId);
    blockId.delete();
    insert.delete();
    for (const config of photoMarkers) {
      if (config.name === name) return config;
    }
  }
}
