import type { DRViewer } from "@/open-cloud/DRViewer";
import { Logger } from "@/logger";
import Toolbox from "./ODAToolbox";
import { type TextData } from "./TextBuilder";
import {
  type CircleData,
  type EllipseData,
  type PolylineData,
} from "./GeometryBuilder";
import { DEFAULT_LAYER_NAME } from "./LayerBuilder";
import { EntityBuilder } from "./EntityBuilder";
const BLOCK_PREFIX = "__DR_BLOCK_";

export class BlockBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  // Logs the properties of a block
  logBlockProperties(blockId: VisualizeJS.OdTvBlockId) {
    Logger.debug("logBlockProperties", blockId);
    const block = blockId.openObject();
    Logger.debug("Name", block.getName());
    block.delete();
  }
  // create a block from BlockConfig
  // appends an internal tag to the start of the name
  private _create(config: BlockConfig): VisualizeJS.OdTvBlockId {
    const name = BlockBuilder.getFullname(config.name);
    const blockId = this.visViewer.createBlock(name);
    this._appendGeometries(blockId, config.geometries);
    return blockId;
  }
  // only create if block does not exist
  // update allows to update existing block
  create(config: BlockConfig, update = false): VisualizeJS.OdTvBlockId {
    Logger.info(`BlockBuilder : create or get block ${config.name}`);
    if (!this.exist(config.name)) {
      return this._create(config);
    } else {
      // @ts-ignore
      const blockId: VisualizeJS.OdTvBlockId = this.findBlock(config.name);
      if (update) {
        this._update(blockId, config);
      }
      return blockId;
    }
  }
  // delete and recreate block, easier than updating inside entity
  private _update(blockId: VisualizeJS.OdTvBlockId, config: BlockConfig) {
    Logger.info(`Blockbuilder : updating block ${config.name}`);
    const block = blockId.openObject();
    block.clearEntities();
    this._appendGeometries(blockId, config.geometries);
    block.delete();
  }

  private _appendGeometries(
    blockId: VisualizeJS.OdTvBlockId,
    geometries: GeometryDataTyped[]
  ) {
    const block = blockId.openObject();
    const entId = block.appendEntity(block.getName());

    EntityBuilder.appendGeometries(entId, geometries);

    this.viewer.entityBuilder.setProperties(entId, {
      layername: DEFAULT_LAYER_NAME,
    });
    Toolbox.deleteAll([block, entId]);
  }

  findBlock(name: string): VisualizeJS.OdTvBlockId | undefined {
    const fullname = BlockBuilder.getFullname(name);
    const itr = this.visViewer.getBlockIterator();
    let blockId = undefined;
    for (; !itr.done(); itr.step()) {
      const itemId = itr.getBlock();
      if (!itemId.isNull()) {
        const block = itemId.openObject();
        if (block.getName() === fullname) {
          blockId = itemId;
          block.delete();
          break;
        } else block.delete();
      }
      Toolbox.deleteAll([itemId]);
    }
    return blockId;
  }

  // Return true if block with same full name already exist
  exist(name: string): boolean {
    const fullname = BlockBuilder.getFullname(name);
    const itr = this.visViewer.getBlockIterator();
    let exist = false;
    for (; !itr.done(); itr.step()) {
      const blockId = itr.getBlock();
      if (!blockId.isNull()) {
        const block = blockId.openObject();
        const name = block.getName();
        if (name == fullname) {
          exist = true;
          Toolbox.deleteAll([block, blockId]);
          break;
        }
        block.delete();
      }
      Toolbox.deleteAll([blockId]);
    }
    return exist;
  }

  // blocks are named with a prefix to distinct them from non DR block
  // blocks have a name
  static getFullname(name: string): string {
    return `${BLOCK_PREFIX + name}`;
  }

  static getConfigName(blockId: VisualizeJS.OdTvBlockId): string {
    const block = blockId.openObject();
    let name = block.getName();
    if (BlockBuilder.isInternalBlock(blockId)) {
      name = name.substring(BLOCK_PREFIX.length);
    }
    block.delete();
    return name;
  }
  // checks if the block contains DR Prefix
  static isInternalBlock(blockId: VisualizeJS.OdTvBlockId): boolean {
    const block = blockId.openObject();
    const isInternal = block.getName().startsWith(BLOCK_PREFIX);
    block.delete();
    return isInternal;
  }

  log(blockId: VisualizeJS.OdTvBlockId) {
    const block = blockId.openObject();
    Logger.debug(`log block ${block.getName()}`);
    const itr = block.getEntitiesIterator();
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      this.viewer.entityBuilder.logEntityProperties(entId);
    }
  }
}

export interface BlockConfig {
  name: string;
  // the base scale factor (ie textheight)
  // for which the geometry is defined
  scale: number;
  geometries: GeometryDataTyped[];
  attributes: Attribute[];
}

export type GeometryDataTyped =
  | PolylineDataTyped
  | TextDataTyped
  | CircleDataTyped
  | EllipseDataTyped;

type PolylineDataTyped = PolylineData & {
  type: "polyline";
};

type TextDataTyped = TextData & {
  // can only be static text, if you want to edit it for each insert, use attributes
  type: "text";
};

type CircleDataTyped = CircleData & {
  type: "circle";
};

type EllipseDataTyped = EllipseData & {
  type: "ellipse";
};

export type Attribute = {
  name: string;
  data: Omit<TextData, "message">; // message is custom for each attribute
};
